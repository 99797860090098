import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Formsy from "formsy-react";
import DropdownSearch from "../../common/DropdownSearch";
import { Row, Col } from "react-bootstrap";
import { DateRangePicker } from "react-dates";

class ContractExportForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
      focusedInput: null,
      startDate: null,
      endDate: null,
      notes: [],
    };
  }

  onChange = (field, selected) => {
    this.setState({ [field]: selected.value });
  };

  cancel = () => {
    this.refs.form.reset();
    this.props.onCancel();
  };

  submit = (model) => {
    model["id"] = this.props.contract._id;
    model["startDate"] = this.state.startDate;
    model["endDate"] = this.state.endDate;
    this.props.onExport(model);
  };

  handleChangeDate = (startDate, endDate) => {
    if (startDate) {
      this.setState({ startDate });
    }
    if (endDate) {
      this.setState({ endDate });
    }
  };

  handleNoteSelect = (field, selected) => {
    this.setState({ notes: selected });
  };

  render = () => {
    let notes = this.props.contract.notes;
    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={6}>
            <DropdownSearch
              name="contract_template"
              label="Contract Template"
              value={this.state.contract_template}
              onChange={this.onChange}
              disabled={!this.props.editing}
              data={[
                ...this.props.codes
                  .filter((code) => code.field == "contract_template")
                  .map((code) => ({ value: code._id, label: code.label })),
              ]}
            />
          </Col>
          <Col md={6}>
            <DropdownSearch
              name="esma_signatory"
              label="My Choice Signatory"
              value={this.state.esma_signatory}
              onChange={this.onChange}
              disabled={!this.props.editing}
              data={[
                {
                  value: "Paul Medeiros\nPresident/CEO",
                  label: "Paul Medeiros, President/CEO",
                },
                {
                  value: "Cheryl Lazzaro\nCFO/VP of Administration",
                  label: "Cheryl Lazzaro, CFO/VP of Administration",
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DropdownSearch
              isMulti
              name="notes"
              label="Notes"
              value={this.state.notes}
              onChange={this.handleNoteSelect}
              disabled={!this.props.editing}
              data={[
                ...notes.map((note) => ({
                  value: note._id,
                  label: note.title,
                  content: note.note,
                })),
              ]}
            />
          </Col>
          <Col md={6}>
            <Row className="form-group">
              <Col sm={12} xs={12}>
                <label
                  className="control-label"
                  htmlFor="date"
                  style={{ textTransform: "uppercase" }}
                >
                  Start / End Date
                </label>
              </Col>
              <DateRangePicker
                name="date"
                startDate={this.state.startDate}
                startDateId="startDate"
                endDate={this.state.endDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  this.handleChangeDate(startDate, endDate);
                }}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                isOutsideRange={() => false}
                numberOfMonths={1}
                openDirection={"OPEN_UP"}
              />
            </Row>
          </Col>
        </Row>
        <Col md={12} className="action-footer">
          <button
            className="btn btn-danger"
            type="button"
            onClick={this.cancel}
          >
            Cancel
          </button>
          <input className="btn btn-success" type="submit" value="Export PDF" />
        </Col>
      </Formsy>
    );
  };
}

ContractExportForm.propTypes = {
  codes: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  adding: PropTypes.bool,
  onAdd: PropTypes.func,
  onExport: PropTypes.func,
  onEsmaSignatorySelect: PropTypes.func,
  contract: PropTypes.object.isRequired,
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    contractToDelete: state.reducers.contractToDelete,
    contracts: state.reducers.contracts,
    contract: state.reducers.contract,
    calls: state.reducers.calls,
    codes: state.reducers.codes,
  };
}

export default connect(mapStatesToProps)(ContractExportForm);
