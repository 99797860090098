import { push, go } from "react-router-redux";
import * as types from "./actionTypes";
import { showModalSuccess } from "./modalActions";
import { showAlertSuccess, hideAlertSuccess } from "./alertActions";
import contactService from "../services/contactService";

export function loadContactSuccess(contacts) {
  return {
    type: types.LOAD_CONTACT_SUCCESS,
    contacts,
    meta: {
      throttle: true
    }
  };
}

export function getContactSuccess(contact) {
  return { type: types.GET_CONTACT_SUCCESS, contact };
}

export function savingContact(status = true) {
  return { type: types.SAVING_CONTACT, savingContact: status };
}

export function createContactSuccess(contact) {
  return { type: types.CREATE_CONTACT_SUCCESS, contact };
}

export function updateContactSuccess(contact) {
  return { type: types.UPDATE_CONTACT_SUCCESS, contact };
}

export function requestContactId(contactId) {
  return dispatch => {
    dispatch({ type: types.REQUEST_CONTACT_ID, contactToDelete: contactId });
    dispatch(showModalSuccess("contactDeleteModal"));
  };
}

export function deleteContactSuccess(contactId) {
  return { type: types.DELETE_CONTACT_SUCCESS, contactId };
}

export function loadContacts() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return contactService
      .loadContacts()
      .then(data => dispatch(loadContactSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function getContact(id, showCONTACTDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return contactService
      .getContact(id)
      .then(contact => {
        dispatch(getContactSuccess(contact));
        if (showCONTACTDetails) {
          dispatch(showModalSuccess("contactDetailsModal"));
        }
      })
      .catch(error => dispatch(showAlertSuccess(error.description, "error")));
  };
}

export function createContact(contact, navigate = true) {
  return dispatch => {
    dispatch(hideAlertSuccess());
    dispatch(savingContact());
    return contactService
      .createContact(contact)
      .then(createdContact => {
        dispatch(createContactSuccess(createdContact));
        dispatch(savingContact(false));
        dispatch(showAlertSuccess("Contact created successfully", "success"));
        if (navigate == true) {
          dispatch(go(-1));
        }
      })
      .catch(error => {
        dispatch(savingContact(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function cancelAddContact() {
  return dispatch => {
    dispatch(go(-1));
  };
}

export function updateContact(contact) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingContact());
    return contactService
      .updateContact(contact)
      .then(updatedContact => {
        dispatch(updateContactSuccess(updatedContact));
        dispatch(savingContact(false));
        dispatch(showAlertSuccess("Contact updated successfully", "success"));
        dispatch(push("/app/contacts"));
      })
      .catch(error => {
        dispatch(savingContact(false));
        dispatch(showAlertSuccess(error.description, "error"));
      });
  };
}

export function deleteContact(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return contactService
      .deleteContact(id)
      .then(() => {
        dispatch(deleteContactSuccess(id));
        dispatch(showAlertSuccess("Contact deleted successfully", "success"));
      })
      .catch(error => console.warn(error));
  };
}
