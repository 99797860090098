import React, { Component } from "react";
import Loadable from "react-loading-overlay";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = { active: props.active };
  }

  componentDidMount() {
    if (this.props.timeout) {
      setTimeout(
        () => this.setState({ active: false }),
        this.props.timeout || 5000
      );
    }
  }

  render() {
    let { active } = this.state;
    return (
      <div className={`-loading ${active && `-active`}`}>
        <Loadable
          active={active}
          background="none"
          spinner
          color="#cf4b04"
          spinnerSize={"55px"}
        />
      </div>
    );
  }
}

export default Loading;
