import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as contractActions from "../../actions/contractActions";
import * as contactActions from "../../actions/contactActions";
import * as accountActions from "../../actions/accountActions";
import * as userActions from "../../actions/userActions";
import * as modalActions from "../../actions/modalActions";

import ContractForm from "./ContractForm";
import ContactForm from "../contact/ContactForm";
import BreadCrumbs from "../common/BreadCrumbs";
import Modal from "../common/Modal";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class ContractAddPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { parentID: false, editing: true };

    autoBind(this);
  }

  componentDidMount = () => {
    let { accounts, users, actions } = this.props;
    isEmpty(accounts) && actions.loadAccounts();
    isEmpty(users) && actions.loadUsers();
  };

  handleSave = contract => {
    this.props.actions.createContract(contract);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  handleSelect(selected) {
    this.setState({ parentID: selected.value });
  }

  handleAddContact = () => {
    this.props.actions.showModalSuccess("contactAddModal");
  };

  handleCancelContact = () => {
    this.props.actions.hideModal();
  };

  handleSaveContact = async contact => {
    await this.props.actions.createContact(contact, false);
    await this.props.actions.loadAccounts();
    await this.props.actions.hideModal();
  };

  render() {
    let { accounts, codes, users } = this.props;
    let { editing } = this.state;

    let contactForm = (
      <ContactForm
        contact={{}}
        accounts={accounts}
        codes={codes}
        onSave={this.handleSaveContact}
        onCancel={this.handleCancelContact}
        adding
        editing
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[{ label: "Contracts" }, { label: "Add Contract" }]}
          />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ContractForm
              contract={{}}
              accounts={accounts}
              codes={codes}
              users={users}
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              onSelectAccount={this.handleSelect}
              onAddContact={this.handleAddContact}
              editing={editing}
            />
            <Modal
              id="contactAddModal"
              title="Add New Contact"
              body={contactForm}
              modal={this.props.modal}
              close={this.props.actions.hideModal}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ContractAddPage.propTypes = {
  actions: PropTypes.object.isRequired,
  modal: PropTypes.object,
  contract: PropTypes.object,
  accounts: PropTypes.array,
  users: PropTypes.array,
  codes: PropTypes.array
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    accounts: state.reducers.accounts,
    users: state.reducers.users,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...contractActions,
        ...contactActions,
        ...accountActions,
        ...userActions,
        ...modalActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ContractAddPage);
