import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import find from 'lodash/find';

class DynamicHeader extends Component {
  constructor(props, context) {
    super(props, context);

    autoBind(this);
  }

  getHeader() {
    let label = '';
    if (find(this.props.labels, { entity: this.props.entity })) {
      let labelObj = find(this.props.labels, { entity: this.props.entity });
      if (this.props.plural) {
        label = labelObj.label_plural;
      } else {
        label = labelObj.label_single;
      }
    } else {
      label = this.props.entity;
    }

    return label;
  }

  render() {
    return <span>{this.getHeader()}</span>;
  }
}

DynamicHeader.propTypes = {
  state: PropTypes.object,
  labels: PropTypes.array,
  entity: PropTypes.string,
  plural: PropTypes.bool
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state,
    labels: state.reducers.labels.labels
  };
}

export default connect(mapStatesToProps)(DynamicHeader);
