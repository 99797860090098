import React from "react";
import PropTypes from "prop-types";

import { Col } from "react-bootstrap";

import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

const ContractVersions = ({ contract, downloadVersion }) => (
  <Col md={6} xs={12}>
    {!isEmpty(contract.versions) && (
      <div>
        <h3>Version History</h3>
        {orderBy(contract.versions, "created_on", "desc").map(
          (version, idx) => {
            return (
              <p key={version.id}>
                {
                  <a onClick={() => downloadVersion(version)}>
                    {`Version ${contract.versions.length - idx}`}(
                    {moment(version.created_on).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                    )
                  </a>
                }
              </p>
            );
          }
        )}
      </div>
    )}
  </Col>
);

ContractVersions.propTypes = {
  contract: PropTypes.object,
  downloadVersion: PropTypes.func
};

export default ContractVersions;
