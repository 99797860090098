import React from "react";
import PropTypes from "prop-types";
import { LinkContainer } from "react-router-bootstrap";

import Loading from "../common/Loading";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faFolder } from "@fortawesome/free-solid-svg-icons";
import ReactTable from "react-table";
import ReactTooltip from "react-tooltip";

import matchSorter from "match-sorter";

const AccountList = ({
  accounts,
  contracts,
  auth,
  onClickView,
  onClickDelete
}) => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["name"] }),
      filterAll: true
    },
    {
      Header: "Number",
      accessor: "account_number",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [item => item.account_number]
        }),
      filterAll: true
    },
    {
      Header: "Relationship Type",
      accessor: "relationship_type.label",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [item => item["relationship_type.label"]]
        }),
      filterAll: true
    },
    {
      Header: "City",
      accessor: "address.city",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [row => row["address.city"]]
        }),
      filterAll: true
    },
    {
      Header: "State",
      accessor: "address.state",
      Cell: row => (row.value ? row.value.label : "--"),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [row => row.state && row.state.label]
        }),
      filterAll: true
    },
    {
      Header: "Revenue Source",
      accessor: "revenue_source",
      Cell: row => row.value.label,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: [row => row.revenue_source && row.revenue_source.label]
        }),
      filterAll: true
    },
    {
      accessor: "_id",
      filterable: false,
      sortable: false,
      Cell: row => {
        let hasAccounts = contracts.find(
          contract => contract.parent_account._id == row.value
        );
        return (
          <div className="text-center actions">
            <DropdownButton title="Actions" id={"dropdown"}>
              <LinkContainer to={`/app/accounts/${row.value}`}>
                <MenuItem>
                  <FontAwesomeIcon icon={faFolder} />
                  View
                </MenuItem>
              </LinkContainer>
              {auth.maxRole == 9000 && (
                <MenuItem
                  data-tip="This account has associated contracts."
                  data-for="hasAccounts"
                  onClick={() => (!hasAccounts ? onClickDelete(row.value) : "")}
                  disabled={hasAccounts ? true : false}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                  Delete
                </MenuItem>
              )}
              {hasAccounts && (
                <ReactTooltip
                  id="hasAccounts"
                  place="left"
                  type="dark"
                  effect="solid"
                />
              )}
            </DropdownButton>
          </div>
        );
      }
    }
  ];

  const props = {
    getTdProps: (state, rowInfo, column) => {
      return {
        onClick: () => {
          if (column.id !== "_id") {
            onClickView(rowInfo.original._id);
          }
        }
      };
    }
  };

  let isLoading = !accounts.length;

  return (
    <ReactTable
      className="-highlight"
      filterable
      data={accounts}
      LoadingComponent={() => <Loading active={isLoading} />}
      columns={columns}
      {...props}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: "name",
          asc: true
        }
      ]}
    />
  );
};

AccountList.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  codes: PropTypes.array,
  contracts: PropTypes.array,
  onClickDelete: PropTypes.func,
  filter: PropTypes.object,
  onChange: PropTypes.func
};

export default AccountList;
