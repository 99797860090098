import { push } from 'react-router-redux';
import * as types from './actionTypes';
import { showModalSuccess } from './modalActions';
import { showAlertSuccess, hideAlertSuccess } from './alertActions';
import glaccountService from '../services/glaccountService';

export function loadGlaccountSuccess(glaccounts) {
  return {
    type: types.LOAD_GLACCOUNT_SUCCESS,
    glaccounts
  };
}

export function getGlaccountSuccess(glaccount) {
  return {
    type: types.GET_GLACCOUNT_SUCCESS,
    glaccount
  };
}

export function savingGlaccount(status = true) {
  return {
    type: types.SAVING_GLACCOUNT,
    savingGlaccount: status
  };
}

export function createGlaccountSuccess(glaccount) {
  return {
    type: types.CREATE_GLACCOUNT_SUCCESS,
    glaccount
  };
}

export function updateGlaccountSuccess(glaccount) {
  return {
    type: types.UPDATE_GLACCOUNT_SUCCESS,
    glaccount
  };
}

export function requestGlaccountId(glaccountId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_GLACCOUNT_ID,
      glaccountToDelete: glaccountId
    });
    dispatch(showModalSuccess('glaccountDeleteModal'));
  };
}

export function deleteGlaccountSuccess(glaccountId) {
  return {
    type: types.DELETE_GLACCOUNT_SUCCESS,
    glaccountId
  };
}

export function loadGlaccounts() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return glaccountService
      .loadGlaccounts()
      .then(data => dispatch(loadGlaccountSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function getGlaccount(id, showGLACCOUNTDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return glaccountService
      .getGlaccount(id)
      .then(glaccount => {
        dispatch(getGlaccountSuccess(glaccount));
        if (showGLACCOUNTDetails) {
          dispatch(showModalSuccess('glaccountDetailsModal'));
        }
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function createGlaccount(glaccount) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingGlaccount());
    return glaccountService
      .createGlaccount(glaccount)
      .then(createdGlaccount => {
        dispatch(createGlaccountSuccess(createdGlaccount));
        dispatch(savingGlaccount(false));
        dispatch(showAlertSuccess('Glaccount created successfully', 'success'));
        dispatch(push('/app/settings/glaccounts'));
      })
      .catch(error => {
        dispatch(savingGlaccount(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function updateGlaccount(glaccount) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingGlaccount());
    return glaccountService
      .updateGlaccount(glaccount)
      .then(updatedGlaccount => {
        dispatch(updateGlaccountSuccess(updatedGlaccount));
        dispatch(savingGlaccount(false));
        dispatch(showAlertSuccess('Glaccount updated successfully', 'success'));
        dispatch(push('/app/settings/glaccounts'));
      })
      .catch(error => {
        dispatch(savingGlaccount(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function deleteGlaccount(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return glaccountService
      .deleteGlaccount(id)
      .then(() => {
        dispatch(deleteGlaccountSuccess(id));
        dispatch(showAlertSuccess('Glaccount removed', 'success'));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}
