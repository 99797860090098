import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DynamicHeader from "../DynamicHeader";
import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";

class CallMenu extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    let { router } = this.props;

    return (
      <Nav className="sidebar sidebar-nav">
        <IndexLinkContainer
          to={
            router.previousLocation == "/app/call-approval"
              ? "/app/call-approval"
              : router.previousLocation == "/app/invoices/add"
              ? "/app/invoices/add"
              : `/app/calls`
          }
        >
          <NavItem eventKey={1}>
            <FontAwesomeIcon icon={faChevronLeft} />
            All <DynamicHeader entity="Calls" plural />
          </NavItem>
        </IndexLinkContainer>
        <LinkContainer to={"/app/calls/add"}>
          <NavItem eventKey={2}>
            <FontAwesomeIcon icon={faPlus} />
            New Call
          </NavItem>
        </LinkContainer>
        <div className="footer-tag">
          <p>
            ECLIPS v1.0.4 <br /> © 2019 GraVoc Associates, Inc.
          </p>
        </div>
      </Nav>
    );
  }
}

CallMenu.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state,
    auth: state.reducers.auth,
    router: state.reducers.router
  };
}

export default connect(mapStatesToProps)(CallMenu);
