import React from "react";
import PropTypes from "prop-types";

import Loading from "../common/Loading";
import ReactTable from "react-table";

import matchSorter from "match-sorter";

const ClientList = ({ clients }) => {
  const columns = [
    {
      Header: "Client Name",
      accessor: "client_name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["client_name"] }),
      filterAll: true,
    },
    {
      Header: "Contract Name",
      accessor: "contract_name",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["contract_name"] }),
      filterAll: true,
    },
    {
      Header: "Status",
      accessor: "client_status",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["client_status"] }),
      filterable: false,
    },
  ];

  let isLoading = !clients.length;

  return (
    <ReactTable
      className="-highlight"
      filterable
      data={clients}
      LoadingComponent={() => <Loading active={isLoading} />}
      columns={columns}
      defaultPageSize={10}
      defaultSorted={[
        {
          id: "client_name",
          asc: true,
        },
      ]}
    />
  );
};

ClientList.propTypes = {
  clients: PropTypes.array,
  filter: PropTypes.object,
};

export default ClientList;
