import * as endpoints from "./apiEndpoints";
import axios from "axios";

class ContractService {
  static loadContracts(query) {
    let url = `${endpoints.BASE_URL}${endpoints.GET_CONTRACTS}`;

    if (query) {
      const { sorted, filtered, page, pageSize } = query;
      let sort = Object.keys(sorted[0])
        .map(key =>
          key == "id"
            ? sorted[0][key]
            : key == "desc" && sorted[0][key]
            ? -1
            : 1
        )
        .join(":");

      const queryParams = `?q=${filtered}&sort=${sort}&limit=${pageSize}&skip=${pageSize *
        page}`;

      url = url.concat(queryParams);
    }

    return axios.get(url).then(response => response.data);
  }

  static getContract(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CONTRACT}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static createContract(contract) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CONTRACT}`;
    const body = {
      contract: contract
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateContract(contract) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CONTRACT}/${contract.id}`;
    const body = {
      contract: contract
    };

    return axios.put(url, body).then(response => response.data);
  }

  static uploadFile(contract, file) {
    const formData = new FormData();
    // arg for formData.append() must match name in contract/index.js upload single
    formData.append("contractUpload", file);

    const url = `${endpoints.BASE_URL}${endpoints.POST_CONTRACT}/${
      contract._id
    }/upload`;

    return axios
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then(response => {
        return this.createVersion(contract, response.data);
      })
      .then(resp => {
        return resp.data;
      });
  }

  static createVersion(contract, version) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CONTRACT}/${
      contract._id
    }/version`;
    const body = {
      version: {
        path: version.fileName
      }
    };

    return axios.post(url, body).then(response => {
      return response.data;
    });
  }

  static downloadVersion(contract, version) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CONTRACT}/${
      contract._id
    }/version/${encodeURIComponent(version.path)}`;

    return axios.get(url).then(response => {
      // simulate DOM click for download
      const file_path = response.data.url;
      const a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      return response.data;
    });
  }

  static updateVersion(contract, version) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CONTRACT}/${
      contract._id
    }/version`;
    const body = {
      version: version
    };

    return axios.put(url, body).then(response => {
      return response.data;
    });
  }

  static deleteVersion(account, version) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CONTRACT}/${
      account.id
    }/version`;
    const body = {
      version: version
    };

    return axios.delete(url, body).then(response => response.data);
  }

  static createNote(contract, note) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CONTRACT}/${
      contract._id
    }/note`;
    const body = {
      note: note
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateNote(contract, note) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CONTRACT}/${
      contract._id
    }/note`;
    const body = {
      note: note
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteNote(note) {
    const url = `${endpoints.BASE_URL}${
      endpoints.DELETE_CONTRACT
    }/${note}/note`;

    return axios.delete(url).then(response => response.data);
  }

  static createLine(contract, line) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CONTRACT}/${
      contract._id
    }/line`;
    const body = {
      line: line
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateLine(contract, line, data) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CONTRACT}/${
      contract._id
    }/line`;

    let body = data
      ? {
          line: { ...line, metrics: data }
        }
      : { line: line };

    console.log("body", body);

    return axios.put(url, body).then(response => response.data);
  }

  static updateContractStats(contract, line) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CONTRACT}/${
      contract._id
    }/line`;

    const body = {
      line: line
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteLine(contract, line) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CONTRACT}/${
      contract._id
    }/line/delete`;

    const body = {
      line: line
    };

    return axios.post(url, body).then(response => response.data);
  }

  static deleteContract(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CONTRACT}/${id}`;
    return axios.delete(url).then(response => response.data);
  }

  static createClient(contract, client) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CONTRACT}/${
      contract._id
    }/client`;
    const body = {
      client: client
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateClient(contract, client) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CONTRACT}/${
      contract._id
    }/client`;

    let body = { client: client };

    console.log("body", body);

    return axios.put(url, body).then(response => response.data);
  }

  static deleteClient(contract, clientId) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CONTRACT}/${
      contract._id
    }/client/delete`;

    const body = {
      clientId: clientId
    };

    console.log("body", body);

    return axios.post(url, body).then(response => response.data);
  }
}

export default ContractService;
