import * as endpoints from "./apiEndpoints";
import axios from "axios";

class EmailService {
  static sendEmail(email) {
    const url = `${endpoints.BASE_URL}${endpoints.SEND_EMAIL}`;

    // console.log("email", email);

    return axios.post(url, email).then(response => response.data);
  }
}

export default EmailService;
