import React, { Component } from "react";
import PropTypes from "prop-types";

import Formsy from "formsy-react";
import { Input } from "formsy-react-components";
import { Row, Col } from "react-bootstrap";
import DropdownSearch from "../common/DropdownSearch";
import CurrencyInput from "../common/CurrencyInput";

import moment from "moment";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import orderBy from "lodash/orderBy";

class ContractForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { canSubmit: false };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  submit = (model) => {
    this.props.onSave(model);
  };

  onChange = (field, selected) => {
    // Handles Tif
    // if (field == "is_tif") selected.value = selected.value;

    this.setState({ [field]: selected.value }, () => console.log(this.state));
  };

  cancel = () => {
    this.props.onCancel();
  };

  generateContactOptions = () => {
    let contactOptions = [];
    let parentAccount;

    if (!isEmpty(this.props.accounts)) {
      if (this.state.parent_account) {
        parentAccount = find(this.props.accounts, {
          _id: this.state.parent_account,
        });
      } else if (this.props.contract.parent_account) {
        parentAccount = find(this.props.accounts, {
          _id: this.props.contract.parent_account,
        });
      }

      if (parentAccount) {
        parentAccount.contacts.map((contact) => {
          contactOptions.push({
            value: contact._id,
            label: contact.full_name,
          });
        });
      }
    }

    contactOptions = orderBy(contactOptions, "label");

    if (parentAccount) {
      contactOptions.push({
        value: null,
        label: "Add New Contact",
        className: "action",
      });
    }

    return contactOptions;
  };

  generateLocationOptions = () => {
    const locationOptions = [];
    let parentAccount;

    if (!isEmpty(this.props.accounts)) {
      if (this.state.parent_account) {
        parentAccount = find(this.props.accounts, {
          _id: this.state.parent_account,
        });
      } else if (this.props.contract.parent_account) {
        parentAccount = find(this.props.accounts, {
          _id: this.props.contract.parent_account,
        });
      }

      if (parentAccount) {
        parentAccount.locations.map((location) => {
          let state = find(this.props.codes, { _id: location.address.state });
          locationOptions.push({
            value: location._id,
            label: `${location.name} (${location.address.addr1} - ${location.address.city}, ${state.value})`,
          });
        });
      }
    }

    return locationOptions;
  };

  generateCodeOptions = (field) => {
    const codeOptions = this.props.codes
      .filter((codes) => codes.entity == "Contract" && codes.field == field)
      .map((code) => ({
        value: code._id,
        label: code.label,
      }));

    return orderBy(codeOptions, "label");
  };

  render() {
    let { contract, editing } = this.props;

    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={3} xs={12}>
            <Input
              formNoValidate
              name="name"
              label="Contract Name"
              value={contract.name}
              disabled={!editing}
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="parent_account"
              label="Parent Account"
              disabled={!editing}
              onChange={this.onChange}
              value={this.state.parent_account || contract.parent_account}
              data={orderBy(
                [
                  ...this.props.accounts.map((account) => ({
                    value: account._id,
                    label: account.name,
                  })),
                ],
                "label"
              )}
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="stage"
              label="Stage"
              disabled={!editing}
              onChange={this.onChange}
              value={this.state.stage || contract.stage}
              data={orderBy(
                [
                  ...this.props.codes
                    .filter(
                      (codes) =>
                        codes.entity == "Contract" && codes.field == "stage"
                    )
                    .map((code) => ({
                      value: code._id,
                      label: code.label,
                      order: code.order,
                    })),
                ],
                "order"
              )}
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="classification"
              label="Classification"
              disabled={!editing}
              onChange={this.onChange}
              value={this.state.classification || contract.classification}
              data={[...this.generateCodeOptions("classification")]}
            />
          </Col>
          <Col md={3} xs={12}>
            <Input
              formNoValidate
              required
              type="date"
              name="start_date"
              label="Start Date"
              disabled={!editing}
              value={moment.utc(contract.start_date || "").format("YYYY-MM-DD")}
              validations={{
                isValidDate: (values, value) => value <= values["end_date"],
                isValidYear: (values, value) => value >= "1753-01-01",
              }}
              validationErrors={{
                isValidDate: "Start date should be before end date",
                isValidYear: "Please enter a valid Year",
              }}
            />
          </Col>
          <Col md={3} xs={12}>
            <Input
              formNoValidate
              required
              type="date"
              name="end_date"
              label="End Date"
              disabled={!editing}
              value={moment.utc(contract.end_date || "").format("YYYY-MM-DD")}
              validations={{
                isValidDate: (values, value) => value >= values["start_date"],
                isValidYear: (values, value) => value <= "9999-12-31",
              }}
              validationErrors={{
                isValidDate: "End date should be after start date",
                isValidYear: "Please enter a valid Year",
              }}
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="is_tif"
              label="Time in Facility"
              disabled={!editing}
              onChange={this.onChange}
              value={
                this.state.is_tif !== undefined
                  ? this.state.is_tif
                  : contract.is_tif
              }
              data={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="invoice_format"
              label="Invoice Format"
              disabled={!editing}
              onChange={this.onChange}
              value={this.state.invoice_format || contract.invoice_format}
              data={[...this.generateCodeOptions("invoice_format")]}
            />
          </Col>
          <Col md={4} xs={12}>
            <CurrencyInput
              type="number"
              name="max_val"
              label="Max Value"
              placeholder="Max Value"
              disabled={!editing}
              value={contract.max_val || 0}
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              formNoValidate
              type="number"
              name="max_hours"
              label="Max Hours"
              placeholder="Max Hours"
              disabled={!editing}
              value={contract.max_hours}
            />
          </Col>
          <Col md={4} xs={12}>
            <Input
              formNoValidate
              type="number"
              name="max_calls"
              label="Max Calls"
              placeholder="Max Calls"
              disabled={!editing}
              value={contract.max_calls}
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="signatory"
              label="Signatory"
              value={this.state.signatory || contract.signatory}
              disabled={!editing}
              data={[...this.generateContactOptions()]}
              action={this.props.onAddContact}
              onChange={(name, selected) =>
                selected.value == null
                  ? this.props.onAddContact()
                  : this.onChange(name, selected)
              }
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="billing_admin"
              label="Billing Contact"
              value={this.state.billing_admin || contract.billing_admin}
              disabled={!editing}
              data={[...this.generateContactOptions()]}
              onChange={(name, selected) =>
                selected.value === null
                  ? this.props.onAddContact()
                  : this.onChange(name, selected)
              }
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="contract_contact"
              label="Contract Contact"
              value={this.state.contract_contact || contract.contract_contact}
              disabled={!editing}
              data={[...this.generateContactOptions()]}
              onChange={(name, selected) =>
                selected.value === null
                  ? this.props.onAddContact()
                  : this.onChange(name, selected)
              }
            />
          </Col>
          <Col md={3} xs={12}>
            <DropdownSearch
              required
              name="esma_contact"
              label="MyChoice Contact"
              value={this.state.esma_contact || contract.esma_contact}
              disabled={!editing}
              data={orderBy(
                [
                  ...this.props.users
                    .filter((users) => users.roles.length)
                    .map((user) => ({
                      value: user._id,
                      label: user.full_name,
                    })),
                ],
                "label"
              )}
              onChange={(name, selected) =>
                selected.value === null
                  ? this.props.onAddContact()
                  : this.onChange(name, selected)
              }
            />
          </Col>
          <Col md={6} xs={12}>
            <DropdownSearch
              required
              name="bill_to_location"
              label="Bill To Location"
              value={this.state.bill_to_location || contract.bill_to_location}
              onChange={this.onChange}
              disabled={!editing}
              data={[...this.generateLocationOptions()]}
            />
          </Col>
          <Col md={6} xs={12}>
            <DropdownSearch
              required
              name="track_by"
              label="Track By"
              value={this.state.track_by || contract.track_by}
              onChange={this.onChange}
              disabled={!editing}
              data={[...this.generateCodeOptions("track_by")]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} className="action-footer">
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Saving... " : "Save" || ""}
            />
          </Col>
        </Row>
      </Formsy>
    );
  }
}

ContractForm.propTypes = {
  contract: PropTypes.object,
  accounts: PropTypes.array,
  users: PropTypes.array.isRequired,
  codes: PropTypes.array,
  saving: PropTypes.bool.isRequired,
  editing: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onAddContact: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ContractForm;
