import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DynamicHeader from "../DynamicHeader";
import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faUsers } from "@fortawesome/free-solid-svg-icons";

class UserMenu extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    return (
      <Nav className="sidebar sidebar-nav">
        <IndexLinkContainer to="/app/users">
          <NavItem eventKey={1}>
            <FontAwesomeIcon icon={faChevronLeft} />
            All <DynamicHeader entity="Employees" plural />
          </NavItem>
        </IndexLinkContainer>
        <div className="footer-tag">
          <p>
            ECLIPS v1.0.4 <br /> © 2019 GraVoc Associates, Inc.
          </p>
        </div>
      </Nav>
    );
  }
}

UserMenu.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return { state: state, auth: state.reducers.auth };
}

export default connect(mapStatesToProps)(UserMenu);
