// Utilities
export const BASE_URL = API_BASE_URL;
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const FORGOT = "/forgot";
export const RESET = "/reset";
export const SEND_EMAIL = "/email";

// Users
export const GET_USERS = "/users";
export const GET_USER = "/users";
export const GET_USER_BY_TOKEN = "/userByToken";
export const POST_USER = "/users";
export const PUT_USER = "/users";
export const DELETE_USER = "/users";

// ADP
export const GET_ADP_CERTS = "/certs";
export const GET_ADP_WORKERS = "/workers";
export const BULK_UPDATE_ADP_CERTS = "/bulkupdate";

// Departments
// export const GET_DEPARTMENTS = '/departments';
// export const GET_DEPARTMENT = '/departments';
// export const POST_DEPARTMENT = '/departments';
// export const PUT_DEPARTMENT = '/departments';
// export const DELETE_DEPARTMENT = '/departments';

// Accounts
export const GET_ACCOUNTS = "/accounts";
export const GET_ACCOUNT = "/accounts";
export const POST_ACCOUNT = "/accounts";
export const PUT_ACCOUNT = "/accounts";
export const DELETE_ACCOUNT = "/accounts";

// Contacts
export const GET_CONTACTS = "/contacts";
export const GET_CONTACT = "/contacts";
export const POST_CONTACT = "/contacts";
export const PUT_CONTACT = "/contacts";
export const DELETE_CONTACT = "/contacts";

// Labels
export const GET_LABELS = "/labels";
export const GET_LABEL = "/labels";
export const POST_LABEL = "/labels";
export const PUT_LABEL = "/labels";
export const DELETE_LABEL = "/labels";
export const DISPLAY_NAME = "/displayName";

// Licenses
// export const GET_LICENSES = '/licenses';
// export const GET_LICENSE = '/licenses';
// export const POST_LICENSE = '/licenses';
// export const PUT_LICENSE = '/licenses';
// export const DELETE_LICENSE = '/licenses';

// Divisions
export const GET_DIVISIONS = "/divisions";
export const GET_DIVISION = "/divisions";
export const POST_DIVISION = "/divisions";
export const PUT_DIVISION = "/divisions";
export const DELETE_DIVISION = "/divisions";

// Roles
export const GET_ROLES = "/roles";
export const GET_ROLE = "/roles";
export const POST_ROLE = "/roles";
export const PUT_ROLE = "/roles";
export const DELETE_ROLE = "/roles";

// Locations
// export const GET_LOCATIONS = '/locations';
// export const GET_LOCATION = '/locations';
// export const POST_LOCATION = '/locations';
// export const PUT_LOCATION = '/locations';
// export const DELETE_LOCATION = '/locations';

// GL Accounts
export const GET_GLACCOUNTS = "/glaccounts";
export const GET_GLACCOUNT = "/glaccounts";
export const POST_GLACCOUNT = "/glaccounts";
export const PUT_GLACCOUNT = "/glaccounts";
export const DELETE_GLACCOUNT = "/glaccounts";

// Zips
export const GET_ZIPS = "/zips";
export const GET_ZIP = "/zips";
export const POST_ZIP = "/zips";
export const PUT_ZIP = "/zips";
export const DELETE_ZIP = "/zips";

// Items
export const GET_ITEMS = "/items";
export const GET_ITEM = "/items";
export const POST_ITEM = "/items";
export const PUT_ITEM = "/items";
export const DELETE_ITEM = "/items";

// Codes
export const GET_CODES = "/codes";
export const GET_CODE = "/codes";
export const POST_CODE = "/codes";
export const PUT_CODE = "/codes";
export const DELETE_CODE = "/codes";

// Companies
export const GET_COMPANIES = "/companies";
export const GET_COMPANY = "/companies";
export const POST_COMPANY = "/companies";
export const PUT_COMPANY = "/companies";
export const DELETE_COMPANY = "/companies";

// Charge Types
export const GET_CHARGETYPES = "/chargetypes";
export const GET_CHARGETYPE = "/chargetypes";
export const POST_CHARGETYPE = "/chargetypes";
export const PUT_CHARGETYPE = "/chargetypes";
export const DELETE_CHARGETYPE = "/chargetypes";

// Contracts
export const GET_CONTRACTS = "/contracts";
export const GET_CONTRACT = "/contracts";
export const POST_CONTRACT = "/contracts";
export const PUT_CONTRACT = "/contracts";
export const DELETE_CONTRACT = "/contracts";

// Calls
export const GET_CALLS = "/calls";
export const GET_CALL = "/calls";
export const POST_CALL = "/calls";
export const BULK_CALL_APPROVE = "/calls-approve";
export const BULK_CALL_UPDATE = "/calls-update";
export const PUT_CALL = "/calls";
export const DELETE_CALL = "/calls";

// Invoices
export const GET_INVOICES = "/invoices";
export const GET_INVOICE = "/invoices";
export const POST_INVOICE = "/invoices";
export const PUT_INVOICE = "/invoices";
export const DELETE_INVOICE = "/invoices";
export const BULK_INVOICE_APPROVE = "/invoices-approve";
export const BULK_INVOICE_COMPLETE = "/invoices-complete";
export const BULK_UPDATE_INVOICES = "/invoices-bulkupdate";
