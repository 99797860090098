import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";

import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBuilding,
  faUser,
  faAddressBook,
  faCopy,
  faClock,
  faFileInvoice,
  faCalendarCheck,
  faPlus,
  faFileAlt,
  faCheckCircle,
  faExternalLinkAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import ClientPage from "../../client/ClientPage";

class MainMenu extends Component {
  constructor(props, context) {
    super(props, context);
    autoBind(this);
    this.state = {
      showClientsModal: false,
    };
  }

  onClientsClick() {
    this.setState({ showClientsModal: true });
  }

  onHideClientsModal() {
    this.setState({ showClientsModal: false });
  }

  render() {
    let { auth, isMobile } = this.props;
    let userId = auth.user._id;
    let param = "MyChoice+Reports";
    let url = `https://reports.eastersealsma.org/ReportServer?/${param}/Staff%20Call%20Detail%20List&Employee=${userId}`;
    return (
      <div>
        <Nav className="sidebar sidebar-nav">
          {isMobile && (
            <LinkContainer to="/app/calls/add">
              <NavItem>
                <FontAwesomeIcon icon={faPlus} />
                New Call
              </NavItem>
            </LinkContainer>
          )}
          {!isMobile && (
            <IndexLinkContainer to="/app">
              <NavItem>
                <FontAwesomeIcon icon={faHome} />
                Dashboard
              </NavItem>
            </IndexLinkContainer>
          )}
          <LinkContainer exact to="/app/calls">
            <NavItem>
              <FontAwesomeIcon icon={faClock} />
              Time Entries
            </NavItem>
          </LinkContainer>
          {!isMobile && auth.maxRole >= 6000 && (
            <LinkContainer to="/app/call-approval">
              <NavItem>
                <FontAwesomeIcon icon={faCalendarCheck} />
                Time Approval
              </NavItem>
            </LinkContainer>
          )}
          {!isMobile && auth.maxRole == 9000 && (
            <LinkContainer to="/app/invoices">
              <NavItem eventKey={5}>
                <FontAwesomeIcon icon={faFileInvoice} />
                Invoices
              </NavItem>
            </LinkContainer>
          )}
          {!isMobile && auth.maxRole == 9000 && (
            <LinkContainer to="/app/invoice-approval">
              <NavItem eventKey={6}>
                <FontAwesomeIcon icon={faCheckCircle} />
                Invoice Approval
              </NavItem>
            </LinkContainer>
          )}
          {auth.maxRole > 4000 && (
            <LinkContainer to="/app/contracts" eventKey={1}>
              <NavItem>
                <FontAwesomeIcon icon={faCopy} />
                Contracts
              </NavItem>
            </LinkContainer>
          )}
          {auth.maxRole > 4000 && (
            <LinkContainer to="/app/accounts">
              <NavItem eventKey={7}>
                <FontAwesomeIcon icon={faBuilding} />
                Accounts
              </NavItem>
            </LinkContainer>
          )}
          <NavItem onClick={this.onClientsClick}>
            <FontAwesomeIcon icon={faUsers} />
            Clients
          </NavItem>
          {auth.maxRole > 4000 && (
            <LinkContainer to="/app/contacts">
              <NavItem eventKey={8}>
                <FontAwesomeIcon icon={faAddressBook} />
                Contacts
              </NavItem>
            </LinkContainer>
          )}
          {auth.maxRole > 4000 && (
            <LinkContainer to="/app/users">
              <NavItem eventKey={9}>
                <FontAwesomeIcon icon={faUser} />
                Employees
              </NavItem>
            </LinkContainer>
          )}
          {!isMobile && auth.maxRole > 4000 && (
            <LinkContainer to="/app/reports">
              <NavItem eventKey={10}>
                <FontAwesomeIcon icon={faFileAlt} />
                Reports
              </NavItem>
            </LinkContainer>
          )}
          {!isMobile && (
            <a
              href={url}
              style={{
                marginLeft: "30px",
                color: "#f2f3f8",
                paddingBottom: "30px",
              }}
            >
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                style={{ marginRight: "5px" }}
              />
              CALL DETAIL REPORT
            </a>
          )}
          <div className="footer-tag">
            <p>
              ECLIPS v1.0.4 <br /> © 2019 GraVoc Associates, Inc.
            </p>
          </div>
        </Nav>
        {this.state.showClientsModal && (
          <ClientPage onHideModal={this.onHideClientsModal} />
        )}
      </div>
    );
  }
}

MainMenu.propTypes = {
  auth: PropTypes.object,
  labels: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default MainMenu;
