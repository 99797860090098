import * as endpoints from "./apiEndpoints";
import axios from "axios";

class ItemService {
  static loadItems() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_ITEMS}`;
    return axios.get(url).then(response => response.data);
  }

  static getItem(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_ITEM}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static createItem(item) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_ITEM}`;
    const body = {
      item: item
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateItem(item) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_ITEM}/${item.id}`;
    const body = {
      item: item
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteItem(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_ITEM}/${id}`;
    return axios.delete(url).then(response => response.data);
  }
}

export default ItemService;
