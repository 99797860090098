import { push } from 'react-router-redux';
import * as types from './actionTypes';
import { showModalSuccess } from './modalActions';
import { showAlertSuccess, hideAlertSuccess } from './alertActions';
import divisionService from '../services/divisionService';

export function loadDivisionSuccess(divisions) {
  return {
    type: types.LOAD_DIVISION_SUCCESS,
    divisions,
    meta: {
      throttle: true
    }
  };
}

export function getDivisionSuccess(division) {
  return {
    type: types.GET_DIVISION_SUCCESS,
    division
  };
}

export function savingDivision(status = true) {
  return {
    type: types.SAVING_DIVISION,
    savingDivision: status
  };
}

export function createDivisionSuccess(division) {
  return {
    type: types.CREATE_DIVISION_SUCCESS,
    division
  };
}

export function updateDivisionSuccess(division) {
  return {
    type: types.UPDATE_DIVISION_SUCCESS,
    division
  };
}

export function requestDivisionId(divisionId) {
  console.log('divisionId', divisionId);
  return dispatch => {
    dispatch({
      type: types.REQUEST_DIVISION_ID,
      divisionToDelete: divisionId
    });
    dispatch(showModalSuccess('divisionDeleteModal'));
  };
}

export function requestDepartmentId(departmentId) {
  console.log('departmentId', departmentId);
  return dispatch => {
    dispatch({
      type: types.REQUEST_DEPARTMENT_ID,
      departmentToDelete: departmentId
    });
    dispatch(showModalSuccess('departmentDeleteModal'));
  };
}

export function deleteDivisionSuccess(divisionId) {
  return {
    type: types.DELETE_DIVISION_SUCCESS,
    divisionId
  };
}

export function loadDivisions() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return divisionService
      .loadDivisions()
      .then(data => dispatch(loadDivisionSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function getDivision(id, showDIVISIONDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return divisionService
      .getDivision(id)
      .then(division => {
        dispatch(getDivisionSuccess(division));
        if (showDIVISIONDetails) {
          dispatch(showModalSuccess('divisionDetailsModal'));
        }
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function createDivision(division) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingDivision());
    return divisionService
      .createDivision(division)
      .then(createdDivision => {
        dispatch(createDivisionSuccess(createdDivision));
        dispatch(savingDivision(false));
        dispatch(showAlertSuccess('Division created successfully', 'success'));
        // dispatch(push('/app/settings/divisions'));
      })
      .catch(error => {
        console.log('error', error);
        dispatch(savingDivision(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function createDepartment(division, department) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingDivision());
    return divisionService
      .createDepartment(division, department)
      .then(updatedDept => {
        dispatch(updateDivisionSuccess(updatedDept));
        dispatch(savingDivision(false));
        dispatch(
          showAlertSuccess('Department created successfully', 'success')
        );
        dispatch(push('/app/settings/divisions'));
      })
      .catch(error => {
        dispatch(savingDivision(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function updateDepartment(division, department) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingDivision());
    return divisionService
      .updateDepartment(division, department)
      .then(updatedDept => {
        dispatch(updateDivisionSuccess(updatedDept));
        dispatch(savingDivision(false));
        dispatch(showAlertSuccess('Department update successfully', 'success'));
        dispatch(push('/app/settings/divisions'));
      })
      .catch(error => {
        dispatch(savingDivision(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function deleteDepartment(division, department) {
  console.log('division', division);
  console.log('department', department);
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingDivision());
    return divisionService
      .deleteDepartment(division, department)
      .then(updatedDept => {
        dispatch(updateDivisionSuccess(updatedDept));
        dispatch(savingDivision(false));
        dispatch(
          showAlertSuccess('Department deleted successfully', 'success')
        );
        dispatch(push('/app/settings/divisions'));
      })
      .catch(error => {
        dispatch(savingDivision(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function updateDivision(division) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingDivision());
    return divisionService
      .updateDivision(division)
      .then(updatedDivision => {
        dispatch(updateDivisionSuccess(updatedDivision));
        dispatch(savingDivision(false));
        dispatch(showAlertSuccess('Division updated successfully', 'success'));
        dispatch(push('/app/settings/divisions'));
      })
      .catch(error => {
        dispatch(savingDivision(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function deleteDivision(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return divisionService
      .deleteDivision(id)
      .then(() => {
        dispatch(deleteDivisionSuccess(id));
        dispatch(showAlertSuccess('Division removed', 'success'));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}
