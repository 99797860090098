import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Loadable from "react-loading-overlay";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { DropdownButton, MenuItem } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

import find from "lodash/find";

import CallService from "../../../services/callService";

const ContractClientListDropDown = ({
  contract,
  value,
  onClickEdit,
  onClickDelete,
}) => {
  const [hasCalls, setHasCalls] = useState(false);

  useEffect(() => {
    // get hasCalls async and then set it
    const fetchData = async () => {
      try {
        const result = await CallService.getClientHasCalls(contract, value);
        if (result) {
          setHasCalls(result);
        }
      } catch (err) {
        setHasCalls(true);
        console.log(err.message);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="pull-right actions">
      <DropdownButton title="Actions" id={"dropdown"}>
        <MenuItem onClick={() => onClickEdit(value)}>
          <FontAwesomeIcon icon={faFolder} />
          View
        </MenuItem>
        <MenuItem
          data-tip="There are calls associated with this client"
          data-for="hasCalls"
          onClick={() => (!hasCalls ? onClickDelete(value) : "")}
          disabled={hasCalls}
        >
          <FontAwesomeIcon icon={faTrashAlt} className="brand-color" />
          Delete
        </MenuItem>
        {hasCalls && (
          <ReactTooltip id="hasCalls" place="left" type="dark" effect="solid" />
        )}
      </DropdownButton>
    </div>
  );
};

ContractClientListDropDown.propTypes = {
  contract: PropTypes.string,
  value: PropTypes.string,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
};

const ContractClientList = ({ contract, onClickEdit, onClickDelete }) => {
  let clientsView = (
    <Loadable
      active={true}
      background="none"
      className="vertical-center"
      spinner
      color="#cf4b04"
    />
  );

  const columns = [
    {
      Header: "Status",
      accessor: "isActive",
      filterable: false,
      maxWidth: 120,
      Cell: (row) => {
        let status = row.value ? "active" : "inactive";
        return <span className={status}>{status}</span>;
      },
    },
    {
      Header: "Client",
      accessor: "name",
    },
    {
      Header: "Weekly Hours",
      accessor: "weekly_hours",
    },
    {
      Header: "Annual Hours",
      accessor: "annual_hours",
    },
    {
      Header: "",
      accessor: "_id",
      filterable: false,
      Cell: (row) => (
        <ContractClientListDropDown
          contract={contract._id}
          value={row.value}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      ),
    },
  ];

  if (contract.clients) {
    clientsView = (
      <ReactTable
        className="-highlight clients"
        data={contract.clients}
        loading={false}
        columns={columns}
        defaultPageSize={10}
      />
    );
  }

  return <div>{clientsView}</div>;
};

ContractClientList.propTypes = {
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      division: PropTypes.string.isRequired,
    }).isRequired
  ),
  contractId: PropTypes.string,
  trackBy: PropTypes.object,
  contract: PropTypes.object,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default ContractClientList;
