import logoDataUri from "./assets/logoDataUri";

export default {
  createPDF: function (customerObj) {
    this.signatory = customerObj.signatory;
    this.title = customerObj.title;
    this.esmaContact = customerObj.esmaContact;
    this.esmaSignatory = customerObj.esmaSignatory;
    this.startDate = customerObj.startDate;
    this.endDate = customerObj.endDate;
    this.companyName = customerObj.companyName;
    this.customerName = customerObj.customerName;
    this.customerAddress =
      customerObj.streetAddr +
      ", " +
      customerObj.city +
      ", " +
      customerObj.state +
      " " +
      customerObj.zip;
    this.customerStreetAddr = customerObj.streetAddr;
    this.customerCity = customerObj.city;
    this.customerState = customerObj.state;
    this.customerZip = customerObj.zip;
    this.customerPhone = customerObj.phone;
    this.customerFax = customerObj.fax;
    this.customerEmail = customerObj.email;
    this.numberOfLines = customerObj.numberOfLines;
    this.lines = customerObj.lines;
    this.currentDate = customerObj.currentDate;
    this.notes = customerObj.notes;

    let styles = {
      paymentTable: {
        bold: true,
        alignment: "right",
      },
      noticeHeader: {
        bold: true,
        alignment: "center",
      },
      contractHeader: {
        bold: true,
        alignment: "center",
        fontSize: 20,
      },
    };

    let body = [];
    body.push([
      {
        text: "Procedure",
        margin: [8, 5, 20, 5],
        bold: true,
        fillColor: "#CCCCCC",
      },
      {
        text: "Rate",
        margin: [8, 5, 20, 5],
        bold: true,
        fillColor: "#CCCCCC",
      },
      { text: "UOM", margin: [8, 5, 20, 5], bold: true, fillColor: "#CCCCCC" },
    ]);

    for (let i = 0; i < this.lines.length; i++) {
      body.push([
        { text: this.lines[i].item.description, bold: true },
        {
          table: {
            heights: [15],
            body: [
              [
                { text: "$", border: [false, false, false, false], bold: true },
                {
                  text: this.lines[i].rate.toFixed(2),
                  border: [false, false, false, false],
                  fillColor: "#CCCCCC",
                },
              ],
            ],
          },
        },
        { text: this.lines[i].uom.label, bold: true, alignment: "center" },
      ]);
    }

    let notesList = [];
    for (let i = 0; i < this.notes.length; i++) {
      notesList.push([
        {
          ul: [{ text: this.notes[i], margin: [0, 5, 20, 0] }],
        },
      ]);
    }

    let paragraph1 = [
      {
        image: logoDataUri,
        width: 220,
        alignment: "center",
      },
      { text: "My Choice", style: "contractHeader" },

      { text: "Programs and Services Contract", style: "contractHeader" },
      "\n",
      "\n",
      {
        text: [
          "This contract is made on ",
          { text: `${this.currentDate}`, bold: true },
          " by and between ",
          { text: `${this.companyName}`, bold: true },
          " located at ",
          { text: `${this.customerAddress}`, bold: true },
          " hereafter called the “Customer” and My Choice, headquartered at 484 Main Street, Worcester, MA 01608, hereafter called “My Choice”.  The parties to this contract noted above hereby agree as follows:",
        ],
      },
      "\n",
      {
        ol: [
          [
            { text: "Responsibilities of My Choice:", margin: [0, 0, 0, 5] },

            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "Provide qualified personnel, upon request of service by the Customer. ",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Payment of wages and other compensation and benefits to My Choice personnel.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Provide general liability insurance for My Choice personnel.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Provide administrative and clinical supervision of My Choice personnel.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "If necessary, verification of a periodic health examination, negative tuberculosis test or a negative chest x-ray results on file for all My Choice personnel utilized by the customer.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Maintaining on file a resume, verification of references and a CORI check and a copy of any applicable state licensure and/or registration of all My Choice personnel assigned to the Customer as requested.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Should the Customer require specific equipment or supplies, it is not the responsibility of My Choice to pay for such items.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Coordination of services between My Choice and Customer by an My Choice Department Director and/or his/her designee.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "If necessary, My Choice will provide a central contact person for scheduling staff for the facility.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "If necessary, clinical supervision of My Choice personnel, through use of qualified supervisors.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "The terms of this contract are subject to staff availability.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "My Choice personnel will be available during My Choice business hours and may be available for consultation during extended hours on a case-by-case basis.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Responsibilities of the Customer:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "Provide suitable working space and facilities for My Choice personnel.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "To notify My Choice personnel of any change or cancellation of scheduled visits.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Provide complete referral information on each prospective client to My Choice and make available to My Choice on a timely basis individual client records necessary for proper service provision.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Work with My Choice’s program staff in consultation and planning for appropriate service provision.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "The Customer will notify the My Choice Department Director, his/her delegate, or supervisor of any problems or issues involving individual My Choice personnel’s professional practice.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "When necessary, provide orientation for My Choice personnel to the Customer’s policies, programs and standards.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Compensation:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "The Customer will pay My Choice within thirty (30) days for service billed in accordance with the Fee Schedule attached hereto as Exhibit A.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "If applicable, payment for any required client specific medical equipment or therapy supplies is not the responsibility of My Choice.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "My Choice will not bill any third-party payer.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "My Choice will bill the Customer monthly for services agreed upon in this contract rendered during the preceding thirty (30) days.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "My Choice reserves the right to increase rate with thirty (30) days-notice.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "The Customer is responsible for any legal or other fees incurred by My Choice in its efforts to collect any money due on this account.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "A service charge of 1.5% per month will be assessed on any balances over thirty (30) days old.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Should the Customer or their client cancel a scheduled appointment without 24 hours- notice or simply not show up for the appointment, s/he will be assessed an absent visit charge.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "The Customer will pay for My Choice staff time for orientation, case conferences, travel within the Customer facilities, and Customer in-services in accordance with the Fee Schedule attached hereto as Exhibit A, when requested by the Customer.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "The Customer will further pay My Choice in accordance with the Fee Schedule attached hereto as Exhibit A, for documentation in excess of that required by applicable laws and regulations and common professional standards.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Restrictions on Hiring:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "The Customer acknowledges the employment relationship between My Choice and its employees and agrees not to offer employment or to employ through any means, direct or indirect, any My Choice employee under this contract without prior written approval from My Choice.  This prohibition is in effect during the term of this contract, which must be for at least one (1) year.  This prohibition shall continue in effect for a period of one (1) year after termination of the contract or resignation of the employee from My Choice.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Should the Customer wish to enter into a direct or indirect employment or subcontract relationship with an employee of My Choice, the Customer agrees to pay My Choice a non-refundable fee.  The fee will be twenty-five percent (25%) of the employee’s current annualized salary, wages, benefits and other overhead expenses.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Term of the Contract:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: [
                    "This contract shall commence on ",
                    { text: `${this.startDate}`, bold: true },
                    " and shall continue in full force and effect until ",
                    { text: `${this.endDate}`, bold: true },
                    " unless either party shall, not less than thirty (30) days prior to the expiration, notify the other party in writing of its intention to end this contract.",
                  ],
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Assignment:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "This contract may not be assigned by either party without the expressed written consent of the other party.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Termination:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "This contract will be terminated by either party, for uncorrected material breach, if thirty (30) days after receiving written notice of any breach of this contract, the breach remains uncorrected, unless during such period, the breaching party has undertaken reasonable efforts to cure the breach.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "The Customer’s failure to pay, when due, gives My Choice the right to cancel this contract for breach of contract upon written notice to the Customer.  The Customer will have fourteen (14) days upon written receipt of notice to make arrangements for payment to the reasonable satisfaction of My Choice.  Said cancellation shall not limit My Choice right to recover its damages for breach of contract.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "This contract may be terminated, without cause, by either party during the term of this contract, by providing written notice of the intent to terminate in thirty (30) days.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Indemnification:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "The Customer and My Choice agree to defend, indemnify and hold harmless the other party from any liability, cost or expense to which the other party may be exposed as a result of a breach of any of the representations and warranties made by the other party in this contract.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "In no event shall either party to this contract be liable for damages arising out of any breach of this contract by either party which is attributable to cause beyond the reasonable control of the other party, including but not limited to, severe weather or accidents which prevent or impair the ability of the other party to travel, strikes and labor disputes, and other similar or dissimilar reasons. Moreover, in no event shall either party be liable for consequential, incidental or special damages resulting from the other’s breach of this contract.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Amendments:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "Any modification of this contract shall be effective only if it is presented in writing and signed by the parties to this contract.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Notices:", margin: [0, 0, 0, 5] },

            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "All notices, requests, demands and other communications required or permitted to be given shall be in writing and shall be considered delivered when hand delivered or mailed by registered or certified mail, return receipt requested. The acceptable mailing addresses for notices, requests, demands or other communications are: ",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
            {
              listType: "none",
              margin: [20, 0, 0, 15],
              table: {
                widths: [100, 150, 150, "*"],
                body: [
                  [
                    "",
                    { text: "My Choice", style: "noticeHeader" },
                    { text: "Customer", style: "noticeHeader" },
                  ],
                  [
                    { text: "Contact Name", style: "paymentTable" },
                    `${this.esmaContact.first_name} ${this.esmaContact.last_name}`,
                    `${this.customerName}`,
                  ],
                  [
                    { text: "Contact Title", style: "paymentTable" },
                    `${this.esmaContact.job_title}`,
                    `${this.title}`,
                  ],
                  [
                    { text: "Company/School", style: "paymentTable" },
                    "My Choice",
                    `${this.companyName}`,
                  ],
                  [
                    { text: "Street Address", style: "paymentTable" },
                    "484 Main Street",
                    `${this.customerStreetAddr}`,
                  ],
                  [
                    { text: "City/State/Zip", style: "paymentTable" },
                    "Worcester, MA 01608",
                    `${this.customerCity}, ${this.customerState}, ${this.customerZip}`,
                  ],
                  [
                    { text: "Phone", style: "paymentTable" },
                    "802 242 756",
                    `${this.customerPhone}`,
                  ],
                  [
                    { text: "Fax", style: "paymentTable" },
                    "802 242 756",
                    `${this.customerFax}`,
                  ],
                  [
                    { text: "E-mail", style: "paymentTable" },
                    `${this.esmaContact.email}`,
                    `${this.customerEmail}`,
                  ],
                ],
              },
            },
          ],
          [
            { text: "Governance:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "This contract will be governed and construed in accordance with the laws of the Commonwealth of Massachusetts.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Any actions or other proceeding brought in connection herein shall be brought only in a court of competent jurisdiction in the Commonwealth of Massachusetts.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Partial Invalidity:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "If any non-material term, provision, covenant, or conditions of this contract is held by a court of competent jurisdiction to be invalid, void, or unenforceable, the remainder provisions shall remain in full force and effect and shall in no way be affected, impaired, or invalidated.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Waiver:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "The waiver by either party, of any breach or any provision of this contract, or of any representation set forth herein, shall not be construed as a waiver of any subsequent breach of the same or any other provision, or representation. The failure to exercise any right hereunder shall not operate as a waiver of such right. All rights and remedies provided for herein are cumulative.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            { text: "Integration:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "This contract supersedes all previous contracts, oral or written between parties and embodies the complete contract between the parties.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
        ],
      }, //end of list
      {
        text: "By signing this agreement, I certify that I am authorized to make business decisions for myself or my organization and agree to the terms listed above.  IN WITNESS THEREOF, the undersigned have entered into this contract on the date specified.",
        margin: [0, 0, 0, 20],
      },
      {
        listType: "none",
        margin: [20, 0, 0, 8],
        table: {
          widths: [200, "*", -200, "*"],
          body: [
            [
              {
                text: "\n\n\n",
                border: [false, false, false, false],
              },
              { text: "\n\n\n", border: [false, false, false, false] },
            ],
            [
              {
                text: `${this.esmaSignatory}\nMy Choice Inc`,
                border: [false, true, false, false],
              },
              { text: "\nDate:", border: [false, true, false, false] },
            ],
            [
              {
                text: "\n\n\n",
                border: [false, false, false, false],
              },
              { text: "\n\n\n", border: [false, false, false, false] },
            ],
            [
              {
                text: `${this.signatory}\n${this.title}\n${this.companyName}`,
                border: [false, true, false, false],
              },
              { text: "\nDate:", border: [false, true, false, false] },
            ],
          ],
        },
      },
      {
        text: "MY CHOICE, Inc.",
        pageBreak: "before",
        fontSize: 20,
      },
      "\n",
      {
        text: "Services Fee Schedule",
        fontSize: 20,
        bold: true,
      },
      "\n",
      {
        table: {
          body: body,
        },
      },
      {
        text: "Notes:",
        margin: [0, 10, 0, 0],
      },
      {
        ul: [notesList],
      },
    ]; //end of paragraph1 array

    let pdfObj = {
      content: paragraph1,
      styles: styles,
    };

    return pdfObj;
  },
};
