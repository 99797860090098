import * as endpoints from './apiEndpoints';
import axios from 'axios';


class ChargetypeService {
  static loadChargetypes() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CHARGETYPES}`;
    
    return axios.get(url).then(response => response.data);
  }

  static getChargetype(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CHARGETYPE}/${id}`;
    
    return axios.get(url).then(response => response.data);
  }

  static createChargetype(chargetype) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CHARGETYPE}`;
    const body = {
      chargetype: chargetype
    };
    
    return axios.post(url, body).then(response => response.data);
  }

  static updateChargetype(chargetype) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CHARGETYPE}/${chargetype.id}`;
    const body = {
      chargetype: chargetype
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteChargetype(id) {

    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CHARGETYPE}/${id}`;
    return axios.delete(url).then(response => response.data);
  }
}

export default ChargetypeService;
