import { push } from 'react-router-redux';
import * as types from './actionTypes';
import { showModalSuccess } from './modalActions';
import { showAlertSuccess, hideAlertSuccess } from './alertActions';
import chargetypeService from '../services/chargetypeService';

export function loadChargetypeSuccess(chargetypes) {
  return {
    type: types.LOAD_CHARGETYPE_SUCCESS,
    chargetypes
  };
}

export function getChargetypeSuccess(chargetype) {
  return {
    type: types.GET_CHARGETYPE_SUCCESS,
    chargetype
  };
}

export function savingChargetype(status = true) {
  return {
    type: types.SAVING_CHARGETYPE,
    savingChargetype: status
  };
}

export function createChargetypeSuccess(chargetype) {
  return {
    type: types.CREATE_CHARGETYPE_SUCCESS,
    chargetype
  };
}

export function updateChargetypeSuccess(chargetype) {
  return {
    type: types.UPDATE_CHARGETYPE_SUCCESS,
    chargetype
  };
}

export function requestChargetypeId(chargetypeId) {
  return dispatch => {
    dispatch({
      type: types.REQUEST_CHARGETYPE_ID,
      chargetypeToDelete: chargetypeId
    });
    dispatch(showModalSuccess('chargetypeDeleteModal'));
  };
}

export function deleteChargetypeSuccess(chargetypeId) {
  return {
    type: types.DELETE_CHARGETYPE_SUCCESS,
    chargetypeId
  };
}

export function loadChargetypes() {
  return dispatch => {
    dispatch(hideAlertSuccess());
    return chargetypeService
      .loadChargetypes()
      .then(data => dispatch(loadChargetypeSuccess(data)))
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function getChargetype(id, showCHARGETYPEDetails = false) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return chargetypeService
      .getChargetype(id)
      .then(chargetype => {
        dispatch(getChargetypeSuccess(chargetype));
        if (showCHARGETYPEDetails) {
          dispatch(showModalSuccess('chargetypeDetailsModal'));
        }
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}

export function createChargetype(chargetype) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingChargetype());
    return chargetypeService
      .createChargetype(chargetype)
      .then(createdChargetype => {
        dispatch(createChargetypeSuccess(createdChargetype));
        dispatch(savingChargetype(false));
        dispatch(
          showAlertSuccess('Chargetype created successfully', 'success')
        );
        dispatch(push('/app/settings/chargetypes'));
      })
      .catch(error => {
        dispatch(savingChargetype(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function updateChargetype(chargetype) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    dispatch(savingChargetype());
    return chargetypeService
      .updateChargetype(chargetype)
      .then(updatedChargetype => {
        dispatch(updateChargetypeSuccess(updatedChargetype));
        dispatch(savingChargetype(false));
        dispatch(
          showAlertSuccess('Chargetype updated successfully', 'success')
        );
        dispatch(push('/app/settings/chargetypes'));
      })
      .catch(error => {
        dispatch(savingChargetype(false));
        dispatch(showAlertSuccess(error.description, 'error'));
      });
  };
}

export function deleteChargetype(id) {
  return (dispatch, getState) => {
    dispatch(hideAlertSuccess());
    return chargetypeService
      .deleteChargetype(id)
      .then(() => {
        dispatch(deleteChargetypeSuccess(id));
        dispatch(showAlertSuccess('Chargetype removed', 'success'));
      })
      .catch(error => dispatch(showAlertSuccess(error.description, 'error')));
  };
}
