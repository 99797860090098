import axios from 'axios';
import * as endpoints from './apiEndpoints';

class ContactService {
  static loadContacts() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CONTACTS}`;

    return axios.get(url).then(response => response.data);
  }

  static getContact(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CONTACT}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static createContact(contact) {
    console.log('contactService', contact);
    const url = `${endpoints.BASE_URL}${endpoints.POST_CONTACT}`;

    const body = {
      contact: contact
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateContact(contact) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CONTACT}/${contact.id}`;

    const body = {
      contact: contact
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteContact(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CONTACT}/${id}`;

    return axios.delete(url).then(response => response.data);
  }
}

export default ContactService;
