import { SHOW_ALERT_SUCCESS, HIDE_ALERT_SUCCESS } from "./actionTypes";
import startCase from "lodash/startCase";

export function showAlertSuccess(content, type, format = true) {
  return {
    type: SHOW_ALERT_SUCCESS,
    alert: {
      message: {
        content: format ? startCase(content) : content,
        type
      },
      show: true
    }
  };
}

export function hideAlertSuccess() {
  return {
    type: HIDE_ALERT_SUCCESS,
    alert: {
      message: {
        content: "hide",
        type: ""
      },
      show: false
    },
    meta: {
      throttle: true
    }
  };
}

export function showAlert(content, type) {
  return dispatch => {
    dispatch(showAlertSuccess(content, type));
  };
}

export function hideAlert() {
  return dispatch => {
    dispatch(hideAlertSuccess());
  };
}
