import React, { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "react-autobind";
import { withFormsy } from "formsy-react";

import Cleave from "cleave.js/react";

class CurrencyInput extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  handleChange(event) {
    let input = event.target.value;
    this.props.setValue(input.replace(/[,$]/g, ""));
  }

  render() {
    let { name, placeholder, value, label, required, disabled } = this.props;

    name = name.replace(/_/g, " ");
    let fieldLabel = required ? (label += " *") : label;

    return (
      <div className="form-group row">
        <label
          className="control-label"
          htmlFor={name}
          style={{ textTransform: "uppercase" }}
        >
          {fieldLabel}
        </label>
        <Cleave
          required={required}
          formNoValidate
          className="form-control"
          options={{ numeral: true, prefix: "$" }}
          name={name}
          label={label}
          placeholder={placeholder}
          value={this.props.getValue() || (0).toFixed(2)}
          disabled={disabled}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

CurrencyInput.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool
};

export default withFormsy(CurrencyInput);
