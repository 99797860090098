import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as contractActions from "../../actions/contractActions";
import * as modalActions from "../../actions/modalActions";
import * as codeActions from "../../actions/codeActions";
import * as callActions from "../../actions/callActions";

import { Row, Col } from "react-bootstrap";
import ContractList from "./ContractList";
import BreadCrumbs from "../common/BreadCrumbs";
import AddButton from "../common/AddButton";
import Modal from "../common/Modal";
import ConfirmModal from "../common/ConfirmModal";
import ContractExportForm from "./contractLines/ContractExportForm";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PDF from "./contractTemplates/pdfTemplate";
import PDFRSO from "./contractTemplates/pdfTemplateRSO";
import PDFRSSN from "./contractTemplates/pdfTemplateRSSN";
import PDFADM from "./contractTemplates/pdfTemplateAddendum";
import find from "lodash/find";
import moment from "moment";

class ContractPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      adding: false,
      editing: true,
      contract: {}
    };

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  componentDidMount = () => {
    this.props.actions.loadContracts();
  };

  onClickExport = id => {
    this.setState({ contractId: id });
    this.props.actions.getContract(id, true);
  };

  handleExport = data => {
    let contract = find(this.props.contracts, { _id: data.id });
    let esmaSignatory = data.esma_signatory;
    let contractType = find(this.props.codes, {
      id: data.contract_template
    });

    let dateRangeStart = data.startDate;
    let dateRangeEnd = data.endDate;

    let notes = data.notes.map(note => note.content);

    if (contractType) {
      let contractTemplateLabel = contractType.label;
      const pdf = this.createPDFTemplate(
        contract,
        contractTemplateLabel,
        esmaSignatory,
        dateRangeStart,
        dateRangeEnd,
        notes
      );
      pdfMake.createPdf(pdf).open();
    }
  };

  // Builds PDF template
  createPDFTemplate = (
    contract,
    template,
    esmaSignatory,
    dateRangeStart,
    dateRangeEnd,
    notes
  ) => {
    let pdfObj = this.createPDFObject(
      contract._id,
      esmaSignatory,
      dateRangeStart,
      dateRangeEnd,
      notes
    );

    switch (template) {
      case "Private Payer":
        return PDF.createPDF(pdfObj);
      case "RS Skilled Nursing Facility":
        return PDFRSSN.createPDF(pdfObj);
      case "Other":
        return PDFRSO.createPDF(pdfObj);
      case "Addendum":
        return PDFADM.createPDF(pdfObj);
    }
  };

  //Creates PDF Template object
  createPDFObject = (
    docID,
    esmaSignatory,
    dateRangeStart,
    dateRangeEnd,
    notes
  ) => {
    let contract = find(this.props.contracts, { _id: docID });
    let allValidLines = contract.lines.filter(line => line.status == false);

    if (contract) {
      let parentAccount = contract.parent_account;
      let contractContact = contract.contract_contact;
      let state = find(this.props.codes, {
        id: parentAccount.locations[0].address.state
      });

      let startDate = moment.utc(contract.start_date).format("MMMM D, YYYY");
      let endDate = moment.utc(contract.end_date).format("MMMM D, YYYY");

      let billingName = `${contract.billing_admin.first_name} ${
        contract.billing_admin.last_name
      }`;

      let esmaContact = contract.esma_contact;

      let signatoryName = `${contract.signatory.first_name} ${
        contract.signatory.last_name
      }`;

      let selectedLines = [];

      let currentDate = moment().format("MMMM D, YYYY");

      //populate selectedLines based on date range selected, or select all lines
      if (!dateRangeStart || !dateRangeEnd) {
        selectedLines = allValidLines;
      } else {
        for (let i = 0; i < allValidLines.length; i++) {
          let lineStart = moment
            .utc(allValidLines[i].start_date)
            .format("MMMM D, YYYY");
          let lineEnd = moment
            .utc(allValidLines[i].end_date)
            .format("MMMM D, YYYY");
          if (
            (moment(dateRangeStart).isBefore(lineEnd, "day") ||
              moment(dateRangeStart).isSame(lineEnd, "day")) &&
            (moment(dateRangeEnd).isAfter(lineStart, "day") ||
              moment(dateRangeEnd).isSame(lineStart, "day"))
          ) {
            selectedLines.push(allValidLines[i]);
          }
        }
      }

      let pdfObject = {
        signatory: signatoryName,
        title: contractContact.title || "",
        customerName: contractContact
          ? `${contractContact.first_name} ${contractContact.last_name}`
          : "",
        companyName: parentAccount.name,
        streetAddr: parentAccount.locations[0].address.addr1,
        city: parentAccount.locations[0].address.city,
        state: state.label,
        zip: parentAccount.locations[0].address.zip,
        startDate: startDate,
        endDate: endDate,
        phone: parentAccount.phone || "",
        fax: parentAccount.fax || "",
        email: contract.signatory.email || "",
        numberOfLines: selectedLines.length,
        lines: selectedLines,
        esmaSignatory: esmaSignatory,
        esmaContact: esmaContact,
        billingName: billingName,
        currentDate: currentDate,
        notes: notes
      };

      return pdfObject;
    }
  };

  onClickDelete = contractId => {
    this.props.actions.requestContractId(contractId);
  };

  handleDelete = async () => {
    await this.props.actions.deleteContract(this.props.contractToDelete);
    await this.props.actions.loadContracts();
  };

  render() {
    let { contracts, codes, auth } = this.props;

    let contractExportForm = (
      <ContractExportForm
        contractId={this.state.contractId}
        className="export-modal"
        line={this.state.line}
        codes={codes}
        onCancel={this.props.actions.hideModal}
        editing={this.state.editing}
        onExport={this.handleExport}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Contracts" }]} />
          <AddButton entity="contracts" auth={auth} />
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ContractList
              contracts={contracts}
              codes={codes}
              auth={auth}
              onClickView={id =>
                this.props.history.push(`/app/contracts/${id}`)
              }
              onClickDelete={this.onClickDelete}
              onClickExport={this.onClickExport}
              makePDF={this.makePDF}
            />
          </Col>
        </Row>
        <Modal
          id="contractDetailsModal"
          title="Export Contract"
          body={contractExportForm}
          modal={this.props.modal}
          close={this.props.actions.hideModal}
        />
        <ConfirmModal
          id="contractDeleteModal"
          title="Delete Contract"
          body="Are you sure you want to delete this contract?"
          modal={this.props.modal}
          close={this.props.actions.hideModal}
          confirm={this.handleDelete}
        />
      </div>
    );
  }
}

ContractPage.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.object,
  contracts: PropTypes.array.isRequired,
  contract: PropTypes.object.isRequired,
  contractToDelete: PropTypes.string,
  codes: PropTypes.array.isRequired
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    contractToDelete: state.reducers.contractToDelete,
    contracts: state.reducers.contracts,
    contract: state.reducers.contract,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...modalActions, ...contractActions, ...callActions, ...codeActions },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ContractPage);
