import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";

import LoginForm from "./LoginForm";
import { Row, Col, Alert } from "react-bootstrap";

class LoginPage extends Component {
  handleSave = user => {
    this.props.actions.loginUser(user);
  };

  render = () => {
    let { auth, savingUser } = this.props;

    return (
      <Row className="auth-page">
        {auth.resetSent && (
          <Col md={6} mdOffset={3} xs={12}>
            <Alert bsStyle="success" className="text-center">
              <strong>Password Reset Sent!</strong>
              <p>
                Please check for an email at the address you provided with a
                link to reset your account password.
              </p>
            </Alert>
          </Col>
        )}
        <Col md={6} xs={12} className="center">
          <img src={LOGO_PATH} />
          <LoginForm onSave={this.handleSave} saving={savingUser} />
        </Col>
      </Row>
    );
  };
}

LoginPage.propTypes = {
  actions: PropTypes.object.isRequired,
  savingUser: PropTypes.bool,
  auth: PropTypes.object
};

function mapStatesToProps(state) {
  return {
    savingUser: state.reducers.savingUser,
    auth: state.reducers.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...userActions }, dispatch)
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(LoginPage);
