import logoDataUri from "./assets/logoDataUri";

export default {
  createPDF: function (customerObj) {
    this.signatory = customerObj.signatory;
    this.title = customerObj.title;
    this.esmaContact = customerObj.esmaContact;
    this.esmaSignatory = customerObj.esmaSignatory;
    this.startDate = customerObj.startDate;
    this.endDate = customerObj.endDate;
    this.companyName = customerObj.companyName;
    this.customerName = customerObj.customerName;
    this.customerAddress =
      customerObj.streetAddr +
      ", " +
      customerObj.city +
      ", " +
      customerObj.state +
      " " +
      customerObj.zip;
    this.customerStreetAddr = customerObj.streetAddr;
    this.customerCity = customerObj.city;
    this.customerState = customerObj.state;
    this.customerZip = customerObj.zip;
    this.customerPhone = customerObj.phone;
    this.customerFax = customerObj.fax;
    this.customerEmail = customerObj.email;
    this.numberOfLines = customerObj.numberOfLines;
    this.lines = customerObj.lines;
    this.currentDate = customerObj.currentDate;
    this.notes = customerObj.notes;

    let styles = {
      paymentTable: {
        bold: true,
        alignment: "right",
      },
      noticeHeader: {
        bold: true,
        alignment: "center",
      },
      contractHeader: {
        bold: true,
        alignment: "center",
        fontSize: 20,
      },
    };

    let body = [];
    body.push([
      {
        text: "Procedure",
        margin: [8, 5, 20, 5],
        bold: true,
        fillColor: "#CCCCCC",
      },
      { text: "Rate", margin: [8, 5, 20, 5], bold: true, fillColor: "#CCCCCC" },
      { text: "UOM", margin: [8, 5, 20, 5], bold: true, fillColor: "#CCCCCC" },
    ]);

    for (let i = 0; i < this.lines.length; i++) {
      body.push([
        { text: this.lines[i].item.description, bold: true },
        {
          table: {
            heights: [15],
            body: [
              [
                { text: "$", border: [false, false, false, false], bold: true },
                {
                  text: this.lines[i].rate.toFixed(2),
                  border: [false, false, false, false],
                  fillColor: "#CCCCCC",
                },
              ],
            ],
          },
        },
        { text: this.lines[i].uom.label, bold: true, alignment: "center" },
      ]);
    }

    let notesList = [];
    for (let i = 0; i < this.notes.length; i++) {
      notesList.push([
        {
          ul: [{ text: this.notes[i], margin: [0, 5, 20, 0] }],
        },
      ]);
    }

    let paragraph1 = [
      {
        image: logoDataUri,
        width: 220,
        alignment: "center",
      },
      { text: "My Choice", style: "contractHeader" },

      { text: "Programs and Services Contract", style: "contractHeader" },
      "\n",
      "\n",
      {
        text: [
          "This contract is made on ",
          { text: `${this.currentDate}`, bold: true },
          " by and between ",
          { text: `${this.companyName}`, bold: true },
          " located at ",
          { text: `${this.customerAddress}`, bold: true },
          " hereafter called the “Customer” and My Choice, headquartered at 484 Main Street, Worcester, MA 01608, hereafter called “My Choice”.  The parties to this contract noted above hereby agree as follows:",
        ],
      },
      "\n",
      {
        ol: [
          [
            { text: "QUALIFICATIONS & LICENSES:", margin: [0, 0, 0, 5] },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                {
                  text: "My Choice warrants that My Choice has all the necessary qualifications, certifications, and/or licenses pursuant to Federal and State law and regulations to provide the services required under this contract. My Choice further warrants that it is not excluded from any state or federal health care programs, or any third party payer program, has not been excluded from any such program, and that no basis exists for such exclusion. My Choice finally warrants that it has not been subject to any final adverse action as defined under the Health Care Fraud and Abuse Data Collection Program.",
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "My Choice agrees to notify Facility within a reasonable period of time if My Choice fails to possess the necessary qualifications to provide services required under this contract.",
                  margin: [0, 0, 0, 3],
                },
              ],
            },
          ],
          [
            {
              text: "DUTIES AND OBLIGATIONS OF FACILITY:",
              margin: [0, 0, 0, 5],
            },
            {
              type: "lower-alpha",
              margin: [10, 0, 0, 8],
              ol: [
                [
                  "Billing:",
                  [
                    {
                      type: "lower-roman",
                      margin: [10, 0, 0, 8],
                      ol: [
                        {
                          text: "Unless otherwise required by applicable federal or state law, rules or regulations, Facility alone shall be responsible for billing residents and/or their respective governmental or other third-party reimbursement sources for therapy services provided to the residents by the My Choice.",
                          margin: [0, 0, 0, 3],
                        },
                      ],
                    },
                  ],
                ],
                [
                  { text: "My Choice Staff:", margin: [0, 0, 0, 5] },
                  [
                    {
                      type: "lower-roman",
                      margin: [10, 0, 0, 8],
                      ol: [
                        {
                          text: "Facility is responsible for My Choice staff orientation upon arrival to the Facility.",
                          margin: [0, 0, 0, 3],
                        },
                        {
                          text: "When scheduling My Choice staff time, Facility must take into consideration requirements for direct versus indirect time, i.e. documentation, updating plan, reporting any significant changes & billing.",
                          margin: [0, 0, 0, 3],
                        },
                        {
                          text: "Facility is responsible for notifying My Choice of any My Choice staff performance issues in a timely manner.",
                          margin: [0, 0, 0, 3],
                        },
                      ],
                    },
                  ],
                ],
                [
                  { text: "Therapy Space:", margin: [0, 0, 0, 5] },

                  [
                    {
                      type: "lower-roman",
                      margin: [10, 0, 0, 8],
                      ol: [
                        {
                          text: "Facility will, at its sole expense, set aside for My Choice’s use, a designated work and storage area inside Facility’s premises adequate for the provision of services. Maintenance of the designated area or space shall be the responsibility of Facility.",
                          margin: [0, 0, 0, 3],
                        },
                      ],
                    },
                  ],
                ],
                [
                  { text: "Equipment:", margin: [0, 0, 0, 5] },
                  [
                    {
                      type: "lower-roman",
                      margin: [10, 0, 0, 8],
                      ol: [
                        {
                          text: "Facility shall provide at its sole expense, the equipment necessary to provide services under contract.",
                          margin: [0, 0, 0, 3],
                        },
                      ],
                    },
                  ],
                ],

                [
                  { text: "Record Maintenance:", margin: [0, 0, 0, 5] },

                  [
                    {
                      type: "lower-roman",
                      margin: [10, 0, 0, 8],
                      ol: [
                        {
                          text: "Facility shall be solely responsible for maintaining the resident records, including MDS assessments and RUGs classifications, relating to the provision of services. Facility shall make available to My Choice for review and inspection on a timely basis and upon request, individual resident treatment, MDS and RUGs classification records necessary for proper evaluation, screening treatment and provision of services. My Choice may incorporate copies of such records into its own records, and Facility shall, where required, obtain the proper consents required to permit such disclosures. Facility shall be responsible for alerting My Choice to any and all Federal, state and local regulations pertaining to the confidentiality of resident records. My Choice agrees to respect and abide by such regulations.",
                          margin: [0, 0, 0, 3],
                        },
                      ],
                    },
                  ],
                ],

                [
                  {
                    text: "Administrative Responsibility:",
                    margin: [0, 0, 0, 5],
                  },

                  [
                    {
                      type: "lower-roman",
                      margin: [10, 0, 0, 8],
                      ol: [
                        {
                          text: "Facility shall retain administrative and professional responsibility for control over and supervision of the provision of services rendered to residents in all respects, as required by state and federal law.",
                          margin: [0, 0, 0, 3],
                        },
                      ],
                    },
                  ],
                ],

                [
                  { text: "Facility Representative:", margin: [0, 0, 0, 5] },

                  [
                    {
                      type: "lower-roman",
                      margin: [10, 0, 0, 8],
                      ol: [
                        {
                          text: "Facility shall designate an individual to whom My Choice shall report, and upon whose authority My Choice shall be entitled to rely for directions and approvals. ",
                          margin: [0, 0, 0, 3],
                        },
                      ],
                    },
                  ],
                ],

                [
                  { text: "Facility Approvals:", margin: [0, 0, 0, 5] },

                  [
                    {
                      type: "lower-roman",
                      margin: [10, 0, 0, 8],
                      ol: [
                        {
                          text: "Facility certifies it has all licenses, Medicare and Medicaid provider certifications and other governmental and regulatory approvals, including occupancy permits, necessary to conduct its operations.",
                          margin: [0, 0, 0, 3],
                        },
                      ],
                    },
                  ],
                ],
              ],
            },
          ],

          [
            [
              {
                text: "DUTIES AND OBLIGATIONS OF My Choice:",
                margin: [0, 0, 0, 5],
              },

              [
                {
                  type: "lower-alpha",
                  margin: [10, 0, 0, 8],
                  ol: [
                    [
                      {
                        text: "Therapy and Related Services: ",
                        margin: [0, 0, 0, 5],
                      },

                      [
                        {
                          type: "lower-roman",
                          ol: [
                            {
                              text: "My Choice will provide to Facility the following services:",
                              margin: [0, 0, 0, 5],
                            },

                            {
                              //numeric
                              margin: [10, 0, 0, 8],
                              ol: [
                                {
                                  text: "Physical, Occupational and Speech Language Pathology services to Facility’s residents including clinical supervision thereof, through use of qualified staff and in accordance with physician’s orders.  Provision of therapist(s) is subject to staff availability.",
                                  margin: [0, 0, 0, 3],
                                },
                                {
                                  text: "Customized plan of care will be designed and implemented to the maximum potential of each resident.",
                                  margin: [0, 0, 0, 3],
                                },
                                {
                                  text: "My Choice will have a central contact person for scheduling staff for the Facility.",
                                  margin: [0, 0, 0, 3],
                                },
                                {
                                  text: "My Choice will follow up in a timely manner on any staffing issues identified by the Facility.",
                                  margin: [0, 0, 0, 3],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      [
                        { text: "Compliance with Law: ", margin: [0, 0, 0, 5] },

                        [
                          {
                            type: "lower-roman",
                            margin: [10, 0, 0, 8],
                            ol: [
                              {
                                text: "My Choice will provide services to Facility’s residents under the terms and conditions of this contract, applicable requirements of federal and state laws and the conditions of participation and reimbursement coverage imposed by applicable governmental and third-party payers.",
                                margin: [0, 0, 0, 3],
                              },
                            ],
                          },
                        ],
                      ],

                      [
                        {
                          text: "Statement of Qualifications:",
                          margin: [0, 0, 0, 5],
                        },
                        [
                          {
                            type: "lower-roman",
                            margin: [10, 0, 0, 8],
                            ol: [
                              {
                                text: "My Choice will be solely responsible for: ",
                                margin: [0, 0, 0, 5],
                              },

                              {
                                //numeric
                                margin: [10, 0, 0, 8],
                                ol: [
                                  {
                                    text: "Payment of wages, compensation and benefits administrative and clinical supervision.",
                                    margin: [0, 0, 0, 3],
                                  },
                                  {
                                    text: "Providing resumes and a copy of state licensure and/or registration of all staff assigned.",
                                    margin: [0, 0, 0, 3],
                                  },
                                  {
                                    text: "Verification that annual evaluation of therapists is completed and on file.",
                                    margin: [0, 0, 0, 3],
                                  },
                                  {
                                    text: "If necessary, verification of a periodic health examination, negative tuberculosis test or a negative chest x-ray results on file for all My Choice personnel utilized by the customer.",
                                    margin: [0, 0, 0, 3],
                                  },
                                  {
                                    text: "Criminal Offense Record Investigation Report.",
                                    margin: [0, 0, 0, 3],
                                  },
                                  {
                                    text: "Applicable state and federal requirement i.e.; I-9",
                                    margin: [0, 0, 0, 3],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      ],

                      [
                        { text: "Record Maintenance:", margin: [0, 0, 0, 5] },

                        [
                          {
                            type: "lower-roman",
                            margin: [10, 0, 0, 8],
                            ol: [
                              {
                                text: "My Choice therapists will provide and maintain written documentation, including appropriate services coding, on individual charts of resident treatment, progress and evaluation in accordance with Facility policies and procedures, and in accordance with requirements of Federal and state governmental agencies and third party payers.",
                                margin: [0, 0, 0, 3],
                              },
                            ],
                          },
                        ],
                      ],
                    ],
                  ],
                },
              ],
            ],
          ],

          [
            [
              { text: "ACCESS TO RECORDS:", margin: [0, 0, 0, 5] },

              [
                {
                  type: "lower-alpha",
                  margin: [10, 0, 0, 8],
                  ol: [
                    [
                      {
                        text: "My Choice agrees to comply with all statutory requirements governing the maintenance of documentation to verify the cost of services rendered under this contract:",
                        margin: [0, 0, 0, 5],
                      },

                      [
                        {
                          type: "lower-roman",
                          margin: [10, 0, 0, 8],
                          ol: [
                            {
                              text: "Until the expiration of five (5) years after the furnishing of such services pursuant to this contract. Upon written request, My Choice shall make available to the Secretary of Health and Human Services (“Secretary of HHS”), the Comptroller General of the United States, the Medicare Intermediary, any other federal or state agency, or any of their duly authorized representatives having the authority or responsibility for the payments for, or supervision of, Facility or My Choice’s services, all contracts, books, documents, and records of My Choice that are necessary to certify and substantiate the nature and extent of such costs; and",
                              margin: [0, 0, 0, 3],
                            },
                            {
                              text: "My Choice carries out any of the duties of this contract through subcontract, such subcontract shall contain a clause to the effect that until the expiration of five (5) years after the furnishing of such services pursuant to such subcontract, upon written request, the subcontract shall make available to the Secretary of Health and Human Services (“Secretary of HHS”), the Comptroller General of the United States, the Medicare Intermediary, any other federal or state agency, or any of their duly authorized representatives having the authority or responsibility for, or supervision of, My Choice’s services the subcontract, books, documents, and records of such organization that are necessary to certify and substantiate the nature and extent of such costs.",
                              margin: [0, 0, 0, 3],
                            },
                          ],
                        },
                      ],
                    ],
                  ],
                },
              ],
            ],
          ],

          [
            [
              { text: "COMPENSATION:", margin: [0, 0, 0, 5] },

              [
                {
                  type: "lower-alpha",
                  margin: [10, 0, 0, 8],
                  ol: [
                    [
                      { text: "Fee Schedule: ", margin: [0, 0, 0, 5] },

                      [
                        {
                          type: "lower-roman",
                          margin: [10, 0, 0, 8],
                          ol: [
                            {
                              text: "Facility shall pay My Choice for services rendered to all residents and Facility in accordance with the Fee Schedule attached hereto as Exhibit A. My Choice reserves the right to increase rates with thirty (30) days’ notice.",
                              margin: [0, 0, 0, 3],
                            },
                          ],
                        },
                      ],
                    ],

                    [
                      { text: "Invoices: ", margin: [0, 0, 0, 5] },

                      [
                        {
                          type: "lower-roman",
                          margin: [10, 0, 0, 8],
                          ol: [
                            {
                              text: "My Choice shall submit an invoice to Facility within five (5) days of the end of each calendar month.",
                              margin: [0, 0, 0, 3],
                            },
                            {
                              text: "My Choice will not bill any individual or third-party payer.",
                              margin: [0, 0, 0, 3],
                            },
                            {
                              text: "Should client specific medical equipment or therapy supplies be required it is not the responsibility of My Choice to pay for such items.",
                              margin: [0, 0, 0, 3],
                            },
                          ],
                        },
                      ],
                    ],
                    [
                      { text: "Remittance:", margin: [0, 0, 0, 5] },
                      [
                        {
                          type: "lower-roman",
                          margin: [10, 0, 0, 8],
                          ol: [
                            {
                              text: "Facility shall remit payment in full as shown on each invoice within thirty (30) days of the issuance of invoice.  A service charge of 1.5% per month will be assessed on any balances over thirty (30) days old.",
                              margin: [0, 0, 0, 3],
                            },
                          ],
                        },
                      ],
                    ],
                  ],
                },
              ],
            ],
          ],
          [
            [
              { text: "TERM OF THE CONTRACT:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: [
                      "This contract shall commence on ",
                      { text: `${this.startDate}`, bold: true },
                      " and shall continue in full force and effect until ",
                      { text: `${this.endDate}`, bold: true },
                      " unless either party shall, not less than thirty (30) days prior to the expiration, notify the other party in writing of its intention to end this contract.",
                    ],
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "TERMINATION: ", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "This contract may be terminated, without cause, by either party during the term of this contract, by providing written notice of the intent to terminate in not less than thirty (30) Days.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "TERMINATION FOR CAUSE:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "This contract will be terminated by either party, for uncorrected material breach, if thirty (30) days after receiving written notice of any breach of this contract, the breach remains uncorrected, unless during such period, the breaching party has undertaken reasonable efforts to cure the breach.  This contract will be terminated by either party immediately in the event of other party’s bankruptcy, insolvency or liquidation.  This contract will be terminated immediately in the event the Facility loses its license or is terminated from Medicare/Medicaid Program or the Provider is excluded from participating in Medicare/Medicaid program.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "INSURANCE:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "My Choice agrees to maintain such insurance as will fully protect both My Choice and Facility from any and all claims of any nature whatsoever for the damage to property or from personal injury, including death which may arise from operations carried on under this contract either by My Choice or by anyone directly or indirectly engaged or employed by My Choice.",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "My Choice will obtain insurance coverage for any acts of professional malpractice or other negligence committed at the Facility by Therapists. The policy will be not less than one million dollars ($1,000,000) per claim and three million dollars ($3,000,000) total per year. Prior to the date on which My Choice first renders services hereunder, My Choice will provide Facility with a copy of a policy or certificate of insurance coverage.",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Both parties shall maintain workers’ compensation insurance for all their respective staff in such amounts as may be required by applicable state law.",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Each party shall provide the other with not less than ten (10) days prior written notice of any expiration or cancellation of such policy or policies.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "INDEMNIFICATION:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "My Choice and Facility agree to defend, indemnify and hold harmless the other party from any liability, cost or expense to which the other party may be exposed as a result of a breach of any of the representations and warranties made by the other party in this contract.",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "In no event shall either party to this contract be liable for damages arising out of any breach of this contract by either party which is attributable to cause beyond the reasonable control of the other party, including but not limited to, imposition of government restrictions and controls, severe weather or accidents which prevent or impair the ability of the other party to travel, strikes and labor disputes, and other similar or dissimilar reasons.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "PROPRIETARY INFORMATION:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "During the term of this contract and at all thereafter in the event of termination of this contract, Facility and My Choice shall not disclose or use for the direct or indirect benefit of any person, association or company any written materials of either parties including, but not limited to manuals or clinical forms made available to either parties. The parties agree not to disclose any provision of this contract to any third party unless required by law.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "HIPAA:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "In accordance with the Health Insurance Portability and Accountability Act (HIPAA), the My Choice meets the criteria of a business associate of the Facility. As such, the My Choice agrees to comply with federal regulations concerning the use and disclosure of individually identifiable health information. The My Choice will:",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Limit any request for protected health information to that which is reasonably necessary to accomplish the purpose for which the request is made;",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Not use or further disclose the information other than as permitted or required by contract or law;",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Use appropriate safeguards to prevent use or disclosure of the information other than as provided for by contract;",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Report to the Facility any use or disclosure of the information not permitted by the contract of which it becomes aware;",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Ensure that any agents or subcontractors to whom the My Choice provides protected health information, on behalf of the Facility, agree to the same restrictions and conditions that apply to the My Choice with respect to such information;",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Make available protected health information to an individual that has right of access to such information under these regulations;",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Make available protected health information for amendment and incorporate any amendments to protected health information in accordance with these regulations (resident’s right to amend medical information);",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Make available the information required for an accounting of disclosures in accordance with these regulations (resident’s right to request and receive documentation of who may have requested a copy of their medical information);",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Make available to the Secretary its internal practices, books, and records relating to the use and disclosure of protected health information for the purposes of determining the Facility’s compliance with these regulations;",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "At the termination of the contract, return or destroy all protected health information, if feasible, and retain no copies of such information;",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Selling protected health information to third parties for their use and re-use. Under the rules providers may not sell names.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "RESTRICTIONS ON HIRING:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "The Customer acknowledges the employment relationship between My Choice and its employees and agrees not to offer employment or to employ through any means, direct or indirect, any My Choice employee under this contract without prior written approval from My Choice.  This prohibition is in effect during the term of this contract, which must be for at least one (1) year.  This prohibition shall continue in effect for a period of one (1) year after termination of the contract or resignation of the employee from My Choice.",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Should the Customer wish to enter into a direct or indirect employment or subcontract relationship with an employee of My Choice, the Customer agrees to pay My Choice a non-refundable fee.  The fee will be twenty-five percent (25%) of the employee’s current annualized salary, wages, benefits and other overhead expenses.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "ASSIGNMENT:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "This contract may not be assigned by either party without the expressed written consent of the other party.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "AMENDMENT:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "Any modification of this contract shall be effective only if it is presented in writing and signed by the parties to this contract.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "NOTICE OF ADVERSE ACTIONS:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "The parties shall notify each other within five (5) business days in writing of any legal, administrative or governmental surveys, investigations reviews, judicial or administrative proceedings or actions initiated against either of them, or against any Provider, which could materially affect their ability to perform their duties and obligations under this contract.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "NOTICE:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "All notices, requests, demands and other communications required or permitted to be given shall be in writing and shall be considered delivered when hand delivered or mailed by registered or certified mail, return receipt requested. The acceptable mailing addresses for notices, requests, demands, or other communications are:",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          // table here
          {
            listType: "none",
            margin: [0, 0, 0, 10],
            table: {
              widths: [100, 150, 150, "*"],
              body: [
                [
                  "",
                  { text: "My Choice", style: "noticeHeader" },
                  { text: "Customer", style: "noticeHeader" },
                ],
                [
                  { text: "Contact Name", style: "paymentTable" },
                  `${this.esmaContact.first_name} ${this.esmaContact.last_name}`,
                  `${this.customerName}`,
                ],
                [
                  { text: "Contact Title", style: "paymentTable" },
                  `${this.esmaContact.job_title}`,
                  `${this.title}`,
                ],
                [
                  { text: "Company/School", style: "paymentTable" },
                  "My Choice",
                  `${this.companyName}`,
                ],
                [
                  { text: "Street Address", style: "paymentTable" },
                  "484 Main Street",
                  `${this.customerStreetAddr}`,
                ],
                [
                  { text: "City/State/Zip", style: "paymentTable" },
                  "Worcester, MA 01608",
                  `${this.customerCity}, ${this.customerState}, ${this.customerZip}`,
                ],
                [
                  { text: "Phone", style: "paymentTable" },
                  "802 242 756",
                  `${this.customerPhone}`,
                ],
                [
                  { text: "Fax", style: "paymentTable" },
                  "802 242 756",
                  `${this.customerFax}`,
                ],
                [
                  { text: "E-mail", style: "paymentTable" },
                  `${this.esmaContact.email}`,
                  `${this.customerEmail}`,
                ],
              ],
            },
          },
          [
            [
              {
                text: "COMPLIANCE WITH FEDERAL ANTI-KICKBACK LAW:",
                margin: [0, 0, 0, 5],
              },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "Nothing in this contract shall be construed as an offer or payment by one party to the other party (or its affiliates) of cash or other remuneration, either directly or indirectly, in exchange for resident referrals or for arrangement for or recommendation of orders for any item or service.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "GOVERNING LAW:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "This contract shall be governed by and construed in accordance with the laws of Massachusetts.",
                    margin: [0, 0, 0, 3],
                  },
                  {
                    text: "Any actions or other proceedings brought in connection herewith shall be brought only in a court of competent jurisdiction in the state(s) of Massachusetts.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "CIVIL RIGHTS:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "My Choice agrees to comply with Title VI of the Civil Rights Act of 1964 and section 504.504 of the Rehabilitation Act of 1973, and all requirements imposed by or pursuant to the regulations of the Department of Health and Human Services issued pursuant to these acts.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "Partial Invalidity:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "In any non-material term, provision, covenant, or conditions of this contract is held by a court of competent jurisdiction to be invalid, void, or unenforceable, the remainder provisions shall remain in full force and effect and shall in no way be affected, impaired, or invalidated.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "Waiver:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "The waiver by either party, of any breach or any provision of this contract, or of any representation set forth herein, shall not be construed as a waiver of any subsequent breach of the same or any other provision, or representation. The failure to exercise any right hereunder shall not operate as a waiver of such right. All rights and remedies provided for herein are cumulative.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
          [
            [
              { text: "Integration:", margin: [0, 0, 0, 5] },
              {
                type: "lower-alpha",
                margin: [10, 0, 0, 8],
                ol: [
                  {
                    text: "This contract supersedes all previous contracts, oral or written between parties and embodies the complete contract between the parties.",
                    margin: [0, 0, 0, 3],
                  },
                ],
              },
            ],
          ],
        ],
      }, // end contract list
      {
        text: "By signing this agreement, I certify that I am authorized to make business decisions for myself or my organization and agree to the terms listed above.  IN WITNESS THEREOF, the undersigned have entered into this contract on the date specified.",
        margin: [0, 0, 0, 20],
      },
      {
        listType: "none",
        margin: [20, 0, 0, 8],
        table: {
          widths: [200, "*", -200, "*"],
          body: [
            [
              {
                text: "\n\n\n",
                border: [false, false, false, false],
              },
              { text: "\n\n\n", border: [false, false, false, false] },
            ],
            [
              {
                text: `${this.esmaSignatory}\nMy Choice`,
                border: [false, true, false, false],
              },
              { text: "\nDate:", border: [false, true, false, false] },
            ],
            [
              {
                text: "\n\n\n",
                border: [false, false, false, false],
              },
              { text: "\n\n\n", border: [false, false, false, false] },
            ],
            [
              {
                text: `${this.signatory}\n${this.title}\n${this.companyName}`,
                border: [false, true, false, false],
              },
              { text: "\nDate:", border: [false, true, false, false] },
            ],
          ],
        },
      },
      {
        text: "MY CHOICE, Inc.",
        pageBreak: "before",
        fontSize: 20,
      },
      "\n",
      {
        text: "Services Fee Schedule",
        fontSize: 20,
        bold: true,
      },
      "\n",
      {
        table: {
          body: body,
        },
      },
      {
        text: "Notes:",
        margin: [0, 10, 0, 0],
      },
      {
        ul: [notesList],
      },
    ]; // end of paragraph1 array

    let pdfObj = {
      content: paragraph1,
      styles: styles,
    };

    return pdfObj;
  },
};
