import React from "react";

import { Row, Col, Jumbotron } from "react-bootstrap";

// const getLinks = () => {
//   const ytLinks = [
//     {
//       title: "Scheduling Time",
//       link: "https://www.youtube.com/embed/BAOFPIx-Y3s"
//     },
//     {
//       title: "Changing Assignment Status (Scheduled to Completed)",
//       link: "https://www.youtube.com/embed/liS8WcBWv24"
//     },
//     {
//       title: "Changing Assignment Status for Skilled Nursing Facilities",
//       link: "https://www.youtube.com/embed/gmSvd59_t6s"
//     },
//     {
//       title: "Entering Non-Work Time (PTO, Jury Duty, Holiday)",
//       link: "https://www.youtube.com/embed/WMFM656HqVI"
//     }
//   ];
//   return ytLinks;
// };

const Dashboard = () => {
  return (
    <div className="content-wrapper">
      <Jumbotron className="text-center">
        <h1>My Choice</h1>
        <p>Contract Management System</p>
      </Jumbotron>
      {/* <Row className="tutorial-videos">
        {getLinks().map((video, i) => (
          <Col key={i} md={6} xs={12}>
            <h4>{video.title}</h4>
            <iframe
              height={315}
              width={"100%"}
              src={video.link}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Col>
        ))}
      </Row> */}
    </div>
  );
};

export default Dashboard;
