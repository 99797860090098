import * as endpoints from "./apiEndpoints";
import axios from "axios";

class CodeService {
  static loadCodes() {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CODES}`;

    return axios.get(url).then(response => response.data);
  }

  static getCode(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CODE}/${id}`;

    return axios.get(url).then(response => response.data);
  }

  static createCode(code) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CODE}`;
    const body = {
      code: code
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateCode(code) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CODE}/${code._id}`;
    const body = {
      code: code
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteCode(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CODE}/${id}`;
    return axios.delete(url).then(response => response.data);
  }
}

export default CodeService;
