import axios from "axios";
import * as endpoints from "./apiEndpoints";

class AccountService {
  static loadAccounts(query) {
    let url = `${endpoints.BASE_URL}${endpoints.GET_ACCOUNTS}`;

    if (query) {
      const { sorted, filtered, page, pageSize } = query;
      let sort = Object.keys(sorted[0])
        .map(key =>
          key == "id"
            ? sorted[0][key]
            : key == "desc" && sorted[0][key]
            ? -1
            : 1
        )
        .join(":");

      const queryParams = `?q=${filtered}&sort=${sort}&limit=${pageSize}&skip=${pageSize *
        page}`;

      url = url.concat(queryParams);
    }
    return axios.get(url).then(response => response.data);
  }

  static getAccount(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_ACCOUNT}/${id}`;
    return axios.get(url).then(response => response.data);
  }

  static createAccount(account) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_ACCOUNT}`;

    const body = {
      account: account
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateAccount(account) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_ACCOUNT}/${account.id}`;

    const body = {
      account: account
    };

    return axios.put(url, body).then(response => response.data);
  }

  static createLocation(account, location) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_ACCOUNT}/${
      account.id
    }/location`;
    const body = {
      location: location
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateLocation(account, location) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_ACCOUNT}/${
      account.id
    }/location`;
    const body = {
      location: location
    };

    console.log("account", account);

    return axios.put(url, body).then(response => response.data);
  }

  static deleteLocation(account, locationId) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_ACCOUNT}/${
      account.id
    }/location/delete`;

    const body = {
      locationId: locationId
    };

    return axios.post(url, body).then(response => response.data);
  }

  static createNote(account, note) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_ACCOUNT}/${
      account.id
    }/note`;
    const body = {
      note: note
    };

    return axios.post(url, body).then(response => response.data);
  }

  static updateNote(account, note) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_ACCOUNT}/${
      account.id
    }/note`;
    const body = {
      note: note
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteNote(account, note) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_ACCOUNT}/${
      account.id
    }/note/delete`;
    const body = {
      note: note
    };

    return axios.post(url, body).then(response => response.data);
  }

  static deleteAccount(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_ACCOUNT}/${id}`;

    return axios.delete(url).then(response => response.data);
  }
}

export default AccountService;
