import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as contractActions from "../../actions/contractActions";
import * as contactActions from "../../actions/contactActions";
import * as accountActions from "../../actions/accountActions";
import * as codeActions from "../../actions/codeActions";
import * as userActions from "../../actions/userActions";
import * as modalActions from "../../actions/modalActions";

import ContractForm from "./ContractForm";
import ContractVersions from "./ContractVersions";
import ContactForm from "../contact/ContactForm";
import BreadCrumbs from "../common/BreadCrumbs";
import EditButton from "../common/EditButton";
import Modal from "../common/Modal";
import Loading from "../common/Loading";
import FileUpload from "../common/FileUpload";
import { Row, Col } from "react-bootstrap";

import isEmpty from "lodash/isEmpty";

class ContractEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { editing: false };
  }

  componentDidMount = () => {
    let { accounts, users, actions } = this.props;

    isEmpty(accounts) && actions.loadAccounts();
    isEmpty(users) && actions.loadUsers();

    actions.getContract(this.props.match.params.id);
  };

  handleSave = contract => {
    let data = {
      id: this.props.contract._id,
      ...contract
    };

    this.props.actions.updateContract(data);
  };

  handleEdit = () => {
    this.setState({ editing: !this.state.editing });
  };

  handleCancel = () => {
    this.props.history.push("/app/contracts");
  };

  handleAddContact = () => {
    this.props.actions.showModalSuccess("contactAddModal");
  };

  handleCancelContact = () => {
    this.props.actions.hideModal();
  };

  handleSaveContact = async contact => {
    await this.props.actions.createContact(contact, false);
    await this.props.actions.loadAccounts();
    await this.props.actions.hideModal();
  };

  uploadVersion = async files => {
    await this.props.actions.addVersion(this.props.contract, files);
    await this.props.actions.getContract(this.props.match.params.id);
  };

  downloadVersion = version => {
    this.props.actions.downloadVersion(this.props.contract, version);
    console.warn("DOWNLOADING", version);
  };

  render() {
    let { contract, savingContract, accounts, codes, users, auth } = this.props;
    let { editing } = this.state;

    let contactForm = (
      <ContactForm
        contact={{}}
        accounts={accounts}
        codes={codes}
        onSave={this.handleSaveContact}
        onCancel={this.handleCancelContact}
        adding
        editing
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs
            breadcrumbs={[
              { label: "Contracts" },
              { label: `${contract.name}` }
            ]}
          />
          <EditButton onClickEdit={this.handleEdit} auth={auth} />
        </Row>
        {!isEmpty(contract) ? (
          <Row className="mt">
            <Col md={12} xs={12}>
              <ContractForm
                contract={contract}
                accounts={accounts}
                codes={codes}
                users={users}
                saving={savingContract}
                editing={editing}
                onSave={this.handleSave}
                onCancel={this.handleCancel}
                onSelectAccount={this.handleSelect}
                onAddContact={this.handleAddContact}
              />
              <Modal
                id="contactAddModal"
                title="Add New Contact"
                body={contactForm}
                modal={this.props.modal}
                close={this.props.actions.hideModal}
              />
            </Col>
            <Row>
              <FileUpload onDrop={this.uploadVersion} />
              <ContractVersions
                contract={contract}
                downloadVersion={this.downloadVersion}
              />
            </Row>
          </Row>
        ) : (
          <Loading active />
        )}
      </div>
    );
  }
}

ContractEditPage.propTypes = {
  actions: PropTypes.object.isRequired,
  modal: PropTypes.object,
  contract: PropTypes.object,
  users: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  codes: PropTypes.array.isRequired,
  params: PropTypes.object,
  labels: PropTypes.array
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state.reducers,
    modal: state.reducers.modal,
    contract: state.reducers.contract,
    savingContract: state.reducers.savingContract,
    accounts: state.reducers.accounts,
    users: state.reducers.users,
    codes: state.reducers.codes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...contractActions,
        ...contactActions,
        ...accountActions,
        ...userActions,
        ...codeActions
      },
      dispatch
    )
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(ContractEditPage);
