import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as accountActions from "../../actions/accountActions";
import * as contractActions from "../../actions/contractActions";
import * as modalActions from "../../actions/modalActions";

import Modal from "../common/Modal";
import ClientList from "./ClientList";

const ClientPage = ({ modal, actions, contracts, onHideModal }) => {
  useEffect(() => {
    actions.loadContracts();
  }, [contracts]);

  useEffect(() => {
    actions.showModal("clientsModal");
  }, []);

  const data = [];

  contracts.map((contract) => {
    const clients = contract.clients.map((client) => {
      return {
        client_name: client.name,
        client_status: client.status ? "Active" : "Inactive",
        contract_name: contract.name,
      };
    });
    data.push(...clients);
  });

  const clientsList = <ClientList clients={data} />;

  const hideModal = () => {
    actions.hideModal();
    onHideModal();
  };

  return (
    <Modal
      id="clientsModal"
      title="Clients"
      body={clientsList}
      modal={modal}
      close={hideModal}
      modalBodyStyle={{ maxHeight: "675px", overflowY: "auto" }}
    />
  );
};

ClientPage.propTypes = {
  actions: PropTypes.object.isRequired,
  modal: PropTypes.object,
  contracts: PropTypes.array,
  onHideModal: PropTypes.func,
};

ClientPage.defaultProps = {
  contracts: [],
};

function mapStatesToProps(state, ownProps) {
  return {
    modal: state.reducers.modal,
    contracts: state.reducers.contracts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...accountActions,
        ...contractActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ClientPage);
