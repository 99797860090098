import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faFileAlt,
  faChild,
  faStickyNote,
  faAlignJustify
} from "@fortawesome/free-solid-svg-icons";

import find from "lodash/find";

class ContractMenu extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = { trackBy: false };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.contract !== this.props.contract) {
      let trackBy = find(this.props.codes, {
        _id: nextProps.contract.track_by
      });
      if (trackBy) {
        trackBy = trackBy.label;
        this.setState({ trackBy });
      }
    }
  };

  render() {
    let { auth, location } = this.props;

    const id = location.pathname.split("/")[3];

    return (
      <Nav className="sidebar sidebar-nav">
        <IndexLinkContainer to="/app/contracts">
          <NavItem eventKey={1}>
            <FontAwesomeIcon icon={faChevronLeft} />
            All Contracts
          </NavItem>
        </IndexLinkContainer>
        {auth.isAuth && (
          <LinkContainer exact to={`/app/contracts/${id}`}>
            <NavItem eventKey={2}>
              <FontAwesomeIcon icon={faFileAlt} />
              Contract Info
            </NavItem>
          </LinkContainer>
        )}
        {auth.isAuth && (
          <LinkContainer to={`/app/contracts/${id}/lines`}>
            <NavItem eventKey={3}>
              <FontAwesomeIcon icon={faAlignJustify} />
              Procedures
            </NavItem>
          </LinkContainer>
        )}
        {auth.isAuth && this.state.trackBy == "Client" && (
          <LinkContainer to={`/app/contracts/${id}/clients`}>
            <NavItem eventKey={4}>
              <FontAwesomeIcon icon={faChild} />
              Clients
            </NavItem>
          </LinkContainer>
        )}
        {auth.isAuth && (
          <LinkContainer to={`/app/contracts/${id}/notes`}>
            <NavItem eventKey={5}>
              <FontAwesomeIcon icon={faStickyNote} />
              Notes
            </NavItem>
          </LinkContainer>
        )}
        <div className="footer-tag">
          <p>
            ECLIPS v1.0.4 <br /> © 2019 GraVoc Associates, Inc.
          </p>
        </div>
      </Nav>
    );
  }
}

ContractMenu.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  contract: PropTypes.object,
  codes: PropTypes.array
};

function mapStatesToProps(state) {
  return {
    state: state,
    auth: state.reducers.auth,
    contract: state.reducers.contract,
    codes: state.reducers.codes
  };
}

export default connect(mapStatesToProps)(ContractMenu);
