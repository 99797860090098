import * as endpoints from "./apiEndpoints";
import axios, { CancelToken } from "axios";
import isPlainObject from "lodash/isPlainObject";
import queryString from "query-string";

let cancel;

class CallService {
  static loadCalls(body) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CALLS}`;
    return axios.post(url, body).then(response => response.data);
  }

  static loadCallsByQuery(filters) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CALLS}/query`;

    Object.keys(filters).map(
      key =>
        (filters[key] = isPlainObject(filters[key])
          ? JSON.stringify(filters[key])
          : filters[key])
    );

    const body = { ...filters };

    return axios.post(url, body).then(response => response.data);
  }

  static findCalls(body) {
    let url = `${endpoints.BASE_URL}${endpoints.GET_CALLS}/find`;
    if (typeof cancel !== "undefined") cancel();
    const params = queryString.stringify(body, { arrayFormat: "bracket" });
    return axios
      .get(`${url}?${params}`, {
        cancelToken: new CancelToken(c => (cancel = c))
      })
      .then(response => {
        return response.data;
      });
  }

  static getClientHasCalls(contract, client) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CALLS}/clientHasCalls?contract=${contract}&client=${client}`;
    return axios.get(url).then(res => res.data);
  }
  static getCall(id) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CALL}/${id}`;
    return axios.get(url).then(response => response.data);
  }

  static createCall(call) {
    const url = `${endpoints.BASE_URL}${endpoints.POST_CALL}/create`;
    const body = {
      call: call
    };

    return axios.post(url, body).then(response => {
      let call = response.data;
      call.entries.map(entry => (entry["call"] = call._id));
      this.updateCall(call);
      return call;
    });
  }

  static updateCall(call) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CALL}/${call._id}`;
    call.entries.map(entry => {
      if (!entry["call"]) {
        entry["call"] = call._id;
      }
    });

    const body = {
      call: call
    };

    console.log("body", body);

    return axios.put(url, body).then(response => response.data);
  }

  static updateInvoicedCall(call) {
    const url = `${endpoints.BASE_URL}${endpoints.PUT_CALL}/${call._id}/line`;

    const body = {
      call: call
    };

    return axios.put(url, body).then(response => response.data);
  }

  static deleteCall(id) {
    const url = `${endpoints.BASE_URL}${endpoints.DELETE_CALL}/${id}`;
    return axios.delete(url).then(response => response.data);
  }

  static bulkUpdate(idArray, data) {
    const url = `${endpoints.BASE_URL}${endpoints.BULK_CALL_UPDATE}`;
    const body = {
      idArray,
      data
    };
    return axios.post(url, body).then(response => response.data);
  }

  static bulkApprove(idArray, user, stageID) {
    const url = `${endpoints.BASE_URL}${endpoints.BULK_CALL_APPROVE}`;
    const body = {
      idArray,
      userID: user,
      stageID: stageID
    };
    return axios.post(url, body).then(response => response.data);
  }

  static getTotalHours(body) {
    const url = `${endpoints.BASE_URL}${endpoints.GET_CALLS}/totalHours`;
    return axios.post(url, body).then(response => response.data);
  }
}

export default CallService;
