import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faBuilding,
  faAddressBook,
  faStickyNote,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";

class AccountMenu extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Nav className="sidebar sidebar-nav">
        <IndexLinkContainer to="/app/accounts">
          <NavItem eventKey={1}>
            <FontAwesomeIcon icon={faChevronLeft} />
            All Accounts
          </NavItem>
        </IndexLinkContainer>
        <LinkContainer exact to={`/app/accounts/${this.props.account._id}`}>
          <NavItem eventKey={2}>
            <FontAwesomeIcon icon={faBuilding} />
            Info
          </NavItem>
        </LinkContainer>
        <LinkContainer to={`/app/accounts/${this.props.account._id}/locations`}>
          <NavItem eventKey={4}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            Locations
          </NavItem>
        </LinkContainer>
        <LinkContainer to={`/app/accounts/${this.props.account._id}/contacts`}>
          <NavItem eventKey={5}>
            <FontAwesomeIcon icon={faAddressBook} />
            Contacts
          </NavItem>
        </LinkContainer>
        <LinkContainer to={`/app/accounts/${this.props.account._id}/notes`}>
          <NavItem eventKey={3}>
            <FontAwesomeIcon icon={faStickyNote} />
            Notes
          </NavItem>
        </LinkContainer>
        <div className="footer-tag">
          <p>
            ECLIPS v1.0.4 <br /> © 2019 GraVoc Associates, Inc.
          </p>
        </div>
      </Nav>
    );
  }
}

AccountMenu.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  account: PropTypes.object
};

function mapStatesToProps(state, ownProps) {
  return {
    state: state,
    auth: state.reducers.auth,
    account: state.reducers.account
  };
}

export default connect(mapStatesToProps)(AccountMenu);
